<template>
    <div
        class="main"
        :class="{ 'sidebar-open': isProjectSidebarOpen, fullscreen: fullScreenState, mainClasses }"
        data-app
    >
        <main-header />

        <main-sidebar />

        <div class="main-content pt-14">
            <div class="content m-4 mt-0">
                <Nuxt />
            </div>
        </div>

        <notifications-wrapper />

        <!-- <base-notification /> -->

        <send-message :show-modal="getShowNewMessage" />

        <new-task :task-visibility="isNewTaskSidebarOpen" />

        <task-sidebar />

        <create-project-modal :show-modal="$store.state.project.isNewProjectModalOpen" />

        <div v-if="$config.node_env === 'development'" class="fixed bottom-5 right-16 z-50">
            <DarkModeToggler />
        </div>

        {{/* this makes sure that all the task status colors are generated */}}
        <div
            class="bg-system-blue/50 bg-gray-500/50 bg-system-purple/50 bg-success/50 text-system-blue/75 text-gray-500/75 text-system-purple/75 text-success/75"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainHeader from '@/components/Layouts/Header/MainHeader.vue';
import MainSidebar from '@/components/Layouts/MainSidebar.vue';
import NotificationsWrapper from '~/components/UI/Notifications/NotificationsWrapper.vue';
import CreateProjectModal from '~/components/Projects/Create/CreateProjectModal.vue';
import TaskSidebar from '~/components/Tasks/TaskSidebar.vue';
import DarkModeToggler from '@/components/UI/Development/DarkModeToggler';

export default {
    name: 'DefaultLayout',
    components: {
        MainHeader,
        MainSidebar,
        NotificationsWrapper,
        CreateProjectModal,
        TaskSidebar,
        DarkModeToggler,
    },
    // Auth should come first, permission second. The rest does not matter
    middleware: ['auth', 'permission', 'management', 'project'],
    data() {
        return {
            socket: null,
            isFeedbackModalOpen: null,
            isTaskSidebarOpen: true,
            mainClasses: '',
        };
    },
    computed: {
        ...mapGetters({
            fullScreenState: 'getFullScreenState',
            getShowNewMessage: 'messages/getShowNewMessage',
            isProjectSidebarOpen: 'preferences/getSidebarState',
            isNewTaskSidebarOpen: 'tasks/getShowNewTask',
            // breadCrumbState: 'getBreadcrumbState'
        }),
    },
    watch: {
        isNewTaskSidebarOpen(val) {
            if (val) {
                this.$lockScroll();
            } else {
                this.$unlockScroll();
            }
        },
    },
    created() {
        this.checkToken();
        this.calculateScreenSizeCategory();
    },
    mounted() {
        window.onbeforeunload = this.checkOngoingProcessesBeforeClosing;

        window.addEventListener('resize', this.calculateScreenSizeCategory);

        if (window.navigator.userAgent.toLowerCase().includes('firefox')) {
            document.querySelector('html').classList.add('firefox');
            document.querySelector('body').classList.add('firefox');
        }

        this.socket = this.$nuxtSocket({
            name: process.env.node_env,
            auth: {
                apiToken: this.$store.state.auth.token,
            },
        });

        const afJobReadyAlertAudio = new Audio('/audio/pussy-alert.mp3');
        const messageAudio = new Audio('/audio/pussy-message.mp3');

        this.socket.on('ev.event', (res) => {
            if (!res?.type) {
                return;
            }
            switch (res.type) {
                case 'message.send':
                    if (res.payload) {
                        this.$notify({ group: 'message', text: res.payload, type: 'notification' });
                        this.$store.commit('messages/setUnreadMessages', true);
                        messageAudio.play();
                    }
                    this.$store.commit('messages/setRefreshMessagesTable', true);
                    this.$store.dispatch('messages/fetchHeaderMessages');
                    break;
                case 'submit.create':
                    if (this.$route.params.shot && String(res.payload?.shot?.id) === this.$route.params.shot) {
                        this.$store.dispatch('project/fetchActiveShot', {id: res.payload.shot.id});
                    }
                    this.$nuxt.$emit('refreshSubmits', res.payload);
                    // afJobReadyAlertAudio.play();
                    break;
                case 'daily.send':
                    if (res.payload?.afJob) {
                        this.$nuxt.$emit('refreshDailiesTable', res.payload);
                        afJobReadyAlertAudio.play();
                    }
                    break;
                case 'broadcast.send':
                    if (res.payload?.afJob) {
                        this.$nuxt.$emit('refreshBroadcastsTable');
                        afJobReadyAlertAudio.play();
                    }
                    break;
                case 'task.update':
                    this.$nuxt.$emit('refreshTaskTable', res.payload);
                    break;
                case 'project.create':
                    this.$nuxt.$emit('refreshDashboard');
                    break;
                case 'notification.send':
                    if (res.payload) {
                        this.$notify({ title: res.payload, type: 'notification' });
                        this.$store.commit('users/me/setNewNotification', true);
                        this.$store.dispatch('users/me/setMe');
                    }
                    this.$store.commit('users/me/setRefreshNotificationsTable', true);
                    break;
                case 'download.download':
                    this.$nuxt.$emit('fetchDownloads', res);
                    break;
            }
        });

        this.socket.on('connect_error', (err) => {
            if (err.message === 'xhr poll error') {
                console.log("Can't connect to WSS server");
            } else {
                console.log(err.message);
            }
            // this.$notify({ title: err.message, type: 'info' })
        });

        const millisecondsUntilTomorrow = this.$moment().add(1, 'days').startOf('day').diff(this.$moment());
        setTimeout(() => {
            this.$nuxt.$emit('updateCalendars');
        }, millisecondsUntilTomorrow + 1);
    },
    destroyed() {
        window.removeEventListener('resize', this.calculateScreenSizeCategory);
    },
    methods: {
        calculateScreenSizeCategory(e, width = 0) {
            if (!width) width = window.innerWidth;
            let sizeCategory = 0;
            if (width >= 768) {
                sizeCategory = 1;
            }
            if (width >= 1024) {
                sizeCategory = 2;
            }
            if (width >= 1280) {
                sizeCategory = 3;
            }
            if (width >= 1536) {
                sizeCategory = 4;
            }
            this.$store.commit('users/me/setScreenSize', sizeCategory);
        },
        async checkToken() {
            if (this.$store.state.auth.token !== null) {
                await this.$axios
                    .$get(`${process.env.prodAPI}/validate-token/${this.$store.state.auth.token}`)
                    .catch((e) => {
                        if (e.response?.status === 401) {
                            this.$store.dispatch('auth/clearTokens');
                            this.$router.push('/login');
                        }
                    });
            }
            setTimeout(() => this.checkToken(), 30000);
        },
        checkOngoingProcessesBeforeClosing() {
            if (this.$store.state.users.me.unloadBlockers.length > 0) {
                return "Uploads in progress. Are you sure you wish to exit?";
            }
        }
    }
};
</script>
