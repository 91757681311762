export const state = () => ({
    showNewTask: false,
    projectId: null,
    refreshTasksTable: false,
    taskEntityID: null,
    taskType: null,
    taskProject: null,
    taskSequence: null,
    taskSubmit: null,
    savedTaskName: '',
    savedTaskSummary: '',
    savedTaskDescription: '',
    taskStartDate: '',
    taskEndDate: '',
    taskAssignee: '',
    isDispoTimelineRefreshing: false,
    dispoTaskDataToRestore: null,
    isTaskModalOpen: false,
    openTaskId: null,
    isTaskDetailsMounted: false,
    toOpen: null,
});

export const getters = {
    getShowNewTask(state) {
        return state.showNewTask;
    },
    getTaskProjectId(state) {
        return state.projectId;
    },
    getRefreshTasksTable(state) {
        return state.refreshTasksTable;
    },
    getTaskEntityID(state) {
        return state.taskEntityID;
    },
    getTaskType(state) {
        return state.taskType;
    },
    getTaskProject(state) {
        return state.taskProject;
    },
    getSavedTaskName(state) {
        return state.savedTaskName;
    },
    getSavedTaskSummary(state) {
        return state.savedTaskSummary;
    },
    getSavedTaskDescription(state) {
        return state.savedTaskDescription;
    },
    getTaskStartDate(state) {
        return state.taskStartDate;
    },
    getTaskEndDate(state) {
        return state.taskEndDate;
    },
    getTaskAssignee(state) {
        return state.taskAssignee;
    },
    getTaskSequence(state) {
        return state.taskSequence;
    },
    getTaskSubmit(state) {
        return state.taskSubmit;
    },
    getTaskToOpen(state) {
        return state.toOpen;
    },
};

export const mutations = {
    setShowNewTask(state, status) {
        state.showNewTask = status;
    },
    setIsTaskModalOpen(state, payload) {
        state.isTaskModalOpen = payload.state;
        state.openTaskId = payload.id;
    },
    setIsTaskDetailsMounted(state, payload) {
        state.isTaskDetailsMounted = payload;
    },
    setTaskProjectId(state, projectId) {
        state.projectId = parseInt(projectId);
    },
    setRefreshTasksTable(state, status) {
        state.refreshTasksTable = status;
    },
    setTaskEntityID(state, entityId) {
        state.taskEntityID = entityId;
    },
    setTaskType(state, type) {
        state.taskType = type;
    },
    setTaskStartDate(state, date) {
        state.taskStartDate = date;
    },
    setTaskEndDate(state, date) {
        state.taskEndDate = date;
    },
    setTaskAssignee(state, assignee) {
        state.taskAssignee = assignee;
    },
    setTaskProject(state, project) {
        state.taskProject = project;
    },
    setSavedTaskName(state, name) {
        state.savedTaskName = name;
    },
    setSavedTaskSummary(state, name) {
        state.savedTaskSummary = name;
    },
    setSavedTaskDescription(state, name) {
        state.savedTaskDescription = name;
    },
    setTaskSequence(state, sequence) {
        state.taskSequence = sequence;
    },
    setTaskSubmit(state, submit) {
        state.taskSubmit = submit;
    },
    setDispoTaskDataToRestore(state, payload) {
        state.dispoTaskDataToRestore = payload;
    },
    setIsDispoTimelineRefreshing(state, payload) {
        state.isDispoTimelineRefreshing = payload;
    },
    resetTask(state) {
        state.entityId = null;
        state.type = null;
        state.projectID = null;
    },
    setTaskToOpen(state, payload) {
        state.toOpen = payload;
    },
};

export const actions = {
    setShowNewTask(context, payload) {
        context.commit('setShowNewTask', payload.state);
        if (payload.entityId) {
            context.commit('setTaskEntityID', payload.entityId);
        }
        if (payload.type) {
            context.commit('setTaskType', payload.type);
        }
        if (payload.projectID) {
            context.commit('setTaskProject', payload.projectID);
        }
        if (payload.sequence) {
            context.commit('setTaskSequence', payload.sequence);
        }
        if (payload.submit) {
            context.commit('setTaskSubmit', payload.submit);
        }
    },
};
