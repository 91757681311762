<template>
  <tr
    :class="`VueTables__row ${props.rowAttrs.class}`"
    v-bind="props.rowAttrs.attrs"
    @click="props.rowEvents.click"
    @mouseover="hoverRow(props)"
    @mouseleave="hoverOut"
  >
    <vt-child-row-toggler v-if="props.childRowTogglerFirst" :row-id="props.rowId" />
    <vt-table-cell v-for="(column, i) in props.columns" :key="i" :column="column" />
    <vt-child-row-toggler v-if="props.childRowTogglerLast" :row-id="props.rowId" />
  </tr>
</template>

<script>
import VtTableCell from 'vue-tables-2/compiled/components/VtTableCell'
import VtChildRowToggler from 'vue-tables-2/compiled/components/VtChildRowToggler'

export default {
  name: 'MyTableRow',
  components: {
    VtTableCell,
    VtChildRowToggler
  },
  props: {
      props: { type: Object, required: true },
  },
  data() {
    return {
      timer: true,
      timing: null
    }
  },
  methods: {
    hoverRow(props) {
      if (props.columns.includes('performed_by') && this.timer === true) {
        this.timer = false
        this.timing = setTimeout(() => {
          this.$store.dispatch('notifications/setRead', props.rowId)
          this.timer = true
        }, 1000)
      }
    },
    hoverOut() {
      this.timer = false
      clearTimeout(this.timing)
    }
  }
}
</script>
