<template>
  <span>{{ userRole !== 'User' ? userRole : '' }}</span>
</template>

<script>
export default {
  props: {
    config: { type: Object, required: true, default: null },
    role: { type: String, required: true, default: '' }
  },
  computed: {
    userRole() {
      return this.config.users.user_roles.find(data => data.id === this.role).label
    }
  }
}
</script>
