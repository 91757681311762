export const state = () => ({
    areShortcutsEnabled: true,
    isSidebarOpen: true,
    userDispoPerPage: 25,
    dispo24Hours: false,
});

export const getters = {
    getSidebarState(state) {
        return state.isSidebarOpen;
    },
    getDispo24Hours(state) {
        return state.dispo24Hours;
    },
};

export const mutations = {
    setAreShortcutsEnabled(state, payload) {
        state.areShortcutsEnabled = payload;
    },
    setIsSidebarOpen(state, payload) {
        state.isSidebarOpen = payload;
    },
    setUserDispoPerPage(state, payload) {
        state.userDispoPerPage = payload;
    },
    toggleFavoritedUser(state, payload) {
        if (state.favoritedUsers.includes(payload)) {
            state.favoritedUsers = state.favoritedUsers.filter((x) => x !== payload);
        } else {
            state.favoritedUsers.push(payload);
        }
    },
    toggleDispo24Hours(state) {
        state.dispo24Hours = !state.dispo24Hours;
    }
};

export const actions = {
    toggleSidebar({ commit, state }) {
        commit('setIsSidebarOpen', !state.isSidebarOpen);
    },
};
