<template>
    <label class="form-switch" :class="{ disabled: disabled }">
        <input v-model="inputVal" type="checkbox" :name="name" :disabled="disabled" />
        <span class="form-switch-slider" />
        <span v-if="label" class="text">{{ label }}</span>
    </label>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, required: true, default: false },
        name: { type: String, required: true, default: 'checkbox' },
        label: { type: String, required: false, default: '' },
        disabled: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            checkbox: {
                name: 'switch_name',
                label: 'switch_label',
                disabled: false,
            },
        };
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
