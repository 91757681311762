<template>
    <div class="project-list-header py-2 mx-4 my-6 mb-2 flex justify-between items-center relative h-7">
        <transition name="fade_slide">
            <div v-if="$store.state.preferences.isSidebarOpen" class="flex justify-between items-center w-full">
                <p class="font-gotham-700 text-base">My Projects</p>
                <span v-if="$checkPermission('project.create')" class="z-10 relative ml-auto">
                    <v-btn
                        icon
                        x-small
                        color="primary"
                        @click="$store.commit('project/setNewProjectModalVisibility', true)"
                    >
                        <i class="las la-plus-circle" />
                    </v-btn>
                </span>
            </div>
        </transition>
        <transition name="fade">
            <span
                v-if="!$store.state.preferences.isSidebarOpen"
                class="text-white z-10 absolute -top-2 right-0 transition-all right-40"
            >
                <v-btn
                    v-if="$checkPermission('project.create')"
                    icon
                    color="primary"
                    @click="$store.commit('project/setNewProjectModalVisibility', true)"
                >
                    <i class="las la-plus-circle" />
                </v-btn>
            </span>
        </transition>
    </div>
</template>

<script>
export default {};
</script>

<style lang="postcss" scoped>
.project-list-header {
    &:before {
        content: '';
        @apply absolute -bottom-8 -left-4 w-full h-2;
        @apply bg-gradient-to-b;
        @apply from-gray-150 dark:from-dark-900 to-transparent;
        width: calc(100% + 2rem);
    }
}
</style>
