<template>
    <div>
        <notifications :duration="10000" position="bottom left" class="mt-2">
            <template slot="body" slot-scope="{ item, close }">
                <div :class="`rounded p-4 m-2 text-white shadow-card ${notificationColor(item)}`">
                    <div class="flex items-center justify-between text-sm font-gotham-700">
                        <p class="title">{{ item.title }}</p>
                        <button @click="close"><i class="las la-times" /></button>
                    </div>
                    <div v-if="item.text" class="text-xs font-gotham-700 mt-1">
                        {{ item.text }}
                    </div>
                </div>
            </template>
        </notifications>
    </div>
</template>


<script>
export default {
    methods: {
        notificationColor(item) {
            if (item.type === 'error') {
                return 'bg-red';
            } else if (item.type === 'warning') {
                return 'bg-system-orange';
            } else if (item.type === 'success') {
                return 'bg-green';
            } else if (item.type === 'notification') {
                return 'bg-black';
            } else {
                return 'bg-primary';
            }
        },
    },
};
</script>
