import Vue from 'vue';

import VueHotkey from 'v-hotkey';

// import CKEditor from 'ckeditor4-vue'

import Notifications from 'vue-notification';

import VCalendar from 'v-calendar';

import ExpandableImage from 'vue-expandable-image';

Vue.use(VueHotkey);
Vue.use(Notifications);
Vue.use(ExpandableImage);

Vue.use(VCalendar, {
    componentPrefix: 'vc',
});
