<template>
    <div class="project-status-indicator inline-flex items-center justify-start w-6 h-6 min-w-6 min-h-6">
        <span
            class="text-xs w-2 h-2 min-w-2 min-h-2 rounded-full relative"
            :class="{
                'bg-green text-green': status === 'in_progress',
                'bg-system-purple text-system-purple': status === 'preproduction',
                'bg-red-light text-red-light': status === 'closed',
                'bg-gray-600 text-gray-600': status && (status === 'canceled' || status.includes('on_hold')),
                'bg-yellow text-yellow': status === 'ready_to_archive',
                'bg-dark-750 text-dark-750': status === 'archived' || status === 'restoration_in_progress',
            }"
        >
            <span
                class="project-status-indicator__label absolute top-1/2 -translate-y-1/2 left-5 bg-gray-700 border border-gray-600 z-5 whitespace-nowrap p-1 rounded pointer-events-none transition-opacity"
            >
                {{ label }}
            </span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'ProjectStatusIndicator',
    props: {
        status: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.project-status-indicator {
    .project-status-indicator__label {
        opacity: 0;
    }
    &:hover .project-status-indicator__label {
        opacity: 1;
    }
}
</style>
