<template>
    <div class="flex items-center">
        <div v-if="date !== null" class="flex items-center">
            <p :class="`${!preventTextEllipsis ? 'cell-content-max-width-20' : ''} open-full-date-${id}`">
                {{ format(date) }}
            </p>
            <v-tooltip v-if="!hideTooltip" top :activator="`.open-full-date-${id}`" z-index="300">
                <span class="text-xs">
                    {{
                        hideHour && !tooltipHour
                            ? $moment(date).format('YYYY. MMM. D.')
                            : $moment(date).format('YYYY. MMM. D. HH:mm:ss')
                    }}
                </span>
            </v-tooltip>
        </div>
        <div v-else class="flex items-center">-</div>
    </div>
</template>

<script>
export default {
    props: {
        date: { type: [String, Number], required: false, default: '' },
        hideHour: { type: Boolean, required: false, default: false },
        tooltipHour: { type: Boolean, required: false, default: false },
        hideTooltip: { type: Boolean, required: false, default: false },
        preventTextEllipsis: { type: Boolean, required: false, default: false },
        prependFullDate: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            id: null,
        };
    },
    mounted() {
        this.id = this._uid;
    },
    methods: {
        format(d) {
            let prependedDate = '';

            if (this.prependFullDate) {
                prependedDate = this.$moment(d).format('YYYY. MMM. D') + ', ';
            }

            if (this.$moment(d).isSame(new Date(), 'day')) {
                // same day
                if (this.$moment().diff(this.$moment(d)) < 60000) {
                    return `Just now ${this.hideHour ? '' : this.$moment(d).format('HH:mm')}`;
                }
                return prependedDate + this.hideHour ? `Today ${this.hideHour ? '' : this.$moment(d).format('HH:mm')}` : this.$moment(d).fromNow();
            } else if (this.$moment(d).isSame(this.$moment(), 'week')) {
                // same week
                return (
                    prependedDate +
                    this.$moment(d).calendar(null, {
                        lastDay: this.hideHour ? '[Yesterday]' : '[Yesterday] HH:mm',
                        nextDay: this.hideHour ? '[Tomorrow]' : '[Tomorrow] HH:mm',
                        lastWeek: this.hideHour ? '[This] ddd' : '[This] ddd, HH:mm',
                        nextWeek: this.hideHour ? '[This] ddd' : '[This] ddd, HH:mm',
                    })
                );
            } else {
                // anything else
                return (
                    prependedDate +
                    this.$moment(d).calendar(null, {
                        lastDay: this.hideHour ? '[Yesterday]' : '[Yesterday] HH:mm',
                        nextDay: this.hideHour ? '[Tomorrow]' : '[Tomorrow] HH:mm',
                        lastWeek: this.hideHour ? '[Last] ddd' : '[Last] ddd HH:mm',
                        nextWeek: '[Next] ddd',
                        sameElse: () => {
                            if (this.$moment(d).isSame(new Date(), 'year')) {
                                return this.hideHour ? 'MMM D' : 'MMM D, HH:mm';
                            } else {
                                return this.hideHour ? 'YYYY. MMM D' : 'YYYY. MMM D, HH:mm';
                            }
                        },
                    })
                );
            }
        },
    },
};
</script>
