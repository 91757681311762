<template>
    <div v-if="$store.state.preferences.areShortcutsEnabled" class="hidden">
        <span v-hotkey="{ 'alt+t': openDispoTasks }" />
        <span v-hotkey="{ 'alt+r': openDispoRooms }" />
        <span v-hotkey="{ 'alt+p': openDispoProjects }" />
        <span v-hotkey="{ 'alt+e': openDispoTimesheet }" />
        <span v-hotkey="{ h: openDashboard }" />
        <span v-hotkey="{ m: openMessages }" />
        <span v-hotkey="{ n: openNotes }" />
        <span v-hotkey="{ c: openNotifications }" />
        <span v-hotkey="{ t: openMyTasks }" />
        <span v-hotkey="{ p: openProjects }" />
        <span v-hotkey="{ u: openMyPage }" />
        <div v-if="$route.params.id">
            <span v-hotkey="{ w: openWork }" />
            <span v-hotkey="{ d: openDetails }" />
            <span v-hotkey="{ 'alt+1': openWorkShots }" />
            <span v-hotkey="{ 'alt+2': openWorkDailies }" />
            <span v-hotkey="{ 'alt+3': openWorkBroadcasts }" />
            <span v-hotkey="{ 'alt+4': openWorkSummarize }" />
            <span v-hotkey="{ 'alt+5': openWorkResources }" />
            <span v-hotkey="{ 'alt+6': openWorkTasks }" />
        </div>
        <div v-if="adminRoutes.find((data) => data === $route.name)">
            <span v-hotkey="{ 'alt+1': openProjects }" />
            <span v-hotkey="{ 'alt+2': openTasks }" />
            <span v-if="false" v-hotkey="{ 'alt+3': openComparison }" />
            <span v-hotkey="{ 'alt+4': openContacts }" />
            <span v-hotkey="{ 'alt+5': openClients }" />
            <span v-hotkey="{ 'alt+6': openUsers }" />
        </div>
        <div v-if="/^system/.test($route.name)">
            <span v-hotkey="{ 'alt+1': openSystemlog }" />
            <span v-hotkey="{ 'alt+2': openTags }" />
            <span v-hotkey="{ 'alt+3': openAgencies }" />
            <span v-hotkey="{ 'alt+4': openBrands }" />
            <span v-hotkey="{ 'alt+5': openPermissions }" />
            <span v-hotkey="{ 'alt+6': openPresets }" />
            <span v-hotkey="{ 'alt+7': openSettings }" />
        </div>
        <div v-if="$route.name === 'user_management-my_account/me'">
            <span v-hotkey="{ 'alt+1': openMyDispo }" />
            <span v-hotkey="{ 'alt+2': openMyProjects }" />
            <span v-hotkey="{ 'alt+3': openMyTasks }" />
            <span v-hotkey="{ 'alt+4': openMyTimesheet }" />
            <span v-hotkey="{ 'alt+5': openMySettings }" />
            <span v-hotkey="{ 'alt+6': openMyShortcuts }" />
        </div>
        <div v-if="$route.name === 'notes'">
            <span v-hotkey="{ 'alt+1': openNotesMy }" />
            <span v-hotkey="{ 'alt+2': openNotesShared }" />
        </div>
        <div v-if="$route.name === 'messages'">
            <span v-hotkey="{ 'alt+1': openMessagesInbox }" />
            <span v-hotkey="{ 'alt+2': openMessagesSent }" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        // todo: re-insert comparison once it's done
        return {
            adminRoutes: ['projects', 'tasks', 'contacts', 'clients', 'users'],
        };
    },
    methods: {
        openDashboard() {
            this.$router.push('/');
        },
        openMessages() {
            this.$router.push('/messages');
        },
        openNotes() {
            this.$router.push('/notes');
        },
        openNotifications() {
            this.$router.push('/notifications');
        },
        openDispoTasks() {
            this.$router.push('/dispo/users');
        },
        openDispoProjects() {
            this.$router.push('/dispo/projects');
        },
        openDispoRooms() {
            this.$router.push('/dispo/rooms');
        },
        openDispoTimesheet() {
            this.$router.push('/dispo/timesheet');
        },
        openProjects() {
            this.$router.push('/projects');
        },
        openMyPage() {
            this.$router.push('/me');
        },
        /* Project page */
        openWork() {
            this.$router.push(`/projects/${this.$route.params.id}/work`);
        },
        openDetails() {
            this.$router.push(`/projects/${this.$route.params.id}/details`);
        },
        openWorkShots() {
            this.$router.push(`/projects/${this.$route.params.id}/work/shots`);
        },
        openWorkDailies() {
            this.$router.push(`/projects/${this.$route.params.id}/work/dailies`);
        },
        openWorkBroadcasts() {
            this.$router.push(`/projects/${this.$route.params.id}/work/broadcast`);
        },
        openWorkSummarize() {
            this.$router.push(`/projects/${this.$route.params.id}/work/summarize`);
        },
        openWorkResources() {
            this.$router.push(`/projects/${this.$route.params.id}/work/resources`);
        },
        openWorkTasks() {
            this.$router.push(`/projects/${this.$route.params.id}/work/tasks`);
        },
        /* Management page */
        openTasks() {
            this.$router.push('/tasks');
        },
        openComparison() {
            this.$router.push('/comparison');
        },
        openContacts() {
            this.$router.push('/manage/contacts');
        },
        openClients() {
            this.$router.push('/manage/clients');
        },
        openUsers() {
            this.$router.push('/users');
        },
        /* Management page */
        openSystemlog() {
            this.$router.push('/system/system-log');
        },
        openTags() {
            this.$router.push('/system/tags');
        },
        openAgencies() {
            this.$router.push('/manage/agencies');
        },
        openBrands() {
            this.$router.push('/manage/brands');
        },
        openPermissions() {
            this.$router.push('/system/permissions');
        },
        openPresets() {
            this.$router.push('/system/presets');
        },
        openSettings() {
            this.$router.push('/system/settings');
        },
        /* Me page */
        openMyDispo() {
            this.$router.push('/me?tab=my_dispo');
        },
        openMyProjects() {
            this.$router.push('/me?tab=my_projects');
        },
        openMyTasks() {
            this.$router.push('/me?tab=my_tasks');
        },
        openMyTimesheet() {
            this.$router.push('/me?tab=timesheet');
        },
        openMySettings() {
            this.$router.push('/me?tab=settings');
        },
        openMyShortcuts() {
            this.$router.push('/me?tab=shortcuts');
        },
        /* Notes page */
        openNotesMy() {
            this.$router.push('/notes?tab=my_notes');
        },
        openNotesShared() {
            this.$router.push('/notes?tab=shared');
        },
        /* Messages page */
        openMessagesInbox() {
            this.$router.push('/messages?tab=inbox');
        },
        openMessagesSent() {
            this.$router.push('/messages?tab=sent');
        },
    },
};
</script>
