<template>
    <div class="relative z-30">
        <transition name="fade">
            <div
                v-if="$store.state.tasks.isTaskModalOpen"
                class="backdrop fixed top-0 left-0 w-screen h-screen z-10 bg-black/40"
                @click="closeModal"
            ></div>
        </transition>

        <transition name="slide_from_right--large">
            <div
                v-if="$store.state.tasks.isTaskModalOpen"
                class="event-view overflow-auto fixed top-0 right-0 w-3/4 bg-white dark:bg-dark-850 h-screen z-10"
            >
                <div v-if="isTaskLoading" class="pt-5 text-center">
                    <i class="animate-spin las la-circle-notch" />
                </div>

                <task-details
                    v-else
                    class="block pb-32"
                    :task-data="taskData"
                    @updateTaskVisibility="closeModal"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskDetails from '@/components/Tasks/TaskDetails';

export default {
    name: 'TaskSidebar',
    components: {
        TaskDetails,
    },
    data() {
        return {
            taskData: null,
            isTaskLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
        }),
    },
    watch: {
        '$store.state.tasks.isTaskModalOpen'(val) {
            if (val) {
                this.$lockScroll();
                this.fetch();
            } else {
                this.$unlockScroll();
            }
        },
    },
    methods: {
        async fetch() {
            this.isTaskLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/tasks/${this.$store.state.tasks.openTaskId}?scopes[]=created_meta`)
                .then((res) => {
                    this.taskData = {
                        ...res.result,
                        status: this.config.dispo.task_statuses.find((data) => data.id === res.result.status),
                        dateStart: res.result.dateStart ? res.result.dateStart : '',
                        dateEnd: res.result.dateEnd ? res.result.dateEnd : '',
                        description: res.result.description ? res.result.description : '',
                        type: res.result.type ? { id: res.result.type } : '',
                        entity: res.result.entityId ? { id: res.result.entityId } : '',
                        project: res.result.project ? res.result.project : null,
                    };
                })
                .catch((e) => {
                    const error = new Error(e.response.data.message);
                    error.statusCode = e.response.status;
                    throw error;
                })
                .finally(() => {
                    this.isTaskLoading = false;
                });
        },
        closeModal() {
            this.$store.commit('tasks/setIsTaskModalOpen', false);
            if (this.$route.query.task) {
                const queryParams = { ...this.$route.query };
                delete queryParams.task;
                this.$router.replace({ query: queryParams });
            }
        }
    },
};
</script>

<style scoped></style>
