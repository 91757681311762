import Vue from 'vue'
import { ServerTable, ClientTable } from 'vue-tables-2'

import VtDataTable from '~/components/UI/VueTables/VtDataTable.vue'
import SortControl from '~/components/UI/VueTables/SortControl.vue'
import VtTableRow from '~/components/UI/VueTables/VtTableRow.vue'
import VtPerPageSelector from '~/components/UI/VueTables/VtPerPageSelector.vue'
import VtPagination from '~/components/UI/VueTables/VtPagination.vue'

Vue.use(ServerTable, {}, false, 'bootstrap4', {
  sortControl: SortControl,
  tableRow: VtTableRow,
  pagination: VtPagination,
  perPageSelector: VtPerPageSelector,
  dataTable: VtDataTable
})

Vue.use(ClientTable, {}, false, 'bootstrap4', {
  sortControl: SortControl,
  tableRow: VtTableRow,
  pagination: VtPagination,
  perPageSelector: VtPerPageSelector,
  dataTable: VtDataTable
})
