// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/icon-santa-light.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/icons/icon-santa.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".user_actions-enter[data-v-3ac89fa0], .user_actions-leave-to[data-v-3ac89fa0]{opacity:0}.user_actions-enter[data-v-3ac89fa0], .user_actions-leave-to[data-v-3ac89fa0]{--tw-translate-y:-0.75rem;transform:var(--tw-transform)}.user_actions-enter[data-v-3ac89fa0], .user_actions-leave-to[data-v-3ac89fa0]{transform:var(--tw-transform)}.user_actions-enter-active[data-v-3ac89fa0], .user_actions-leave-active[data-v-3ac89fa0]{transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:250ms}.user_actions-enter-to[data-v-3ac89fa0], .user_actions-leave[data-v-3ac89fa0]{opacity:1}.user_actions-enter-to[data-v-3ac89fa0], .user_actions-leave[data-v-3ac89fa0]{--tw-translate-y:0px;transform:var(--tw-transform)}.user_actions-enter-to[data-v-3ac89fa0], .user_actions-leave[data-v-3ac89fa0]{transform:var(--tw-transform)}.main .v-btn.theme--light.v-btn--active[data-v-3ac89fa0]:before, .main .v-btn.theme--light.v-btn--active[data-v-3ac89fa0]:hover:before{opacity:0}.festive-pointy-hat[data-v-3ac89fa0]{position:relative;width:0;height:0}.festive-pointy-hat[data-v-3ac89fa0]:before{content:\"\";display:inline-block;position:relative;background-size:2.5rem 2rem;width:2.5rem;height:2rem;left:-.7rem;top:-1.8rem;}html.dark-mode .festive-pointy-hat[data-v-3ac89fa0]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}html:not(.dark-mode) .festive-pointy-hat[data-v-3ac89fa0]:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
