export const state = () => ({
    isShotDetailsPanelOpen: false,
    isShotDetailsInEditMode: false,
});

export const mutations = {
    setIsShotDetailsPanelOpen(state, payload) {
        state.isShotDetailsPanelOpen = payload;
    },
    toggleIsShotDetailsPanelOpen(state) {
        state.isShotDetailsPanelOpen = !state.isShotDetailsPanelOpen;
    },
    setIsShotDetailsInEditMode(state, payload) {
        state.isShotDetailsInEditMode = payload;
    },
    toggleIsShotDetailsInEditMode(state) {
        state.isShotDetailsInEditMode = !state.isShotDetailsInEditMode;
    },
};
