var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":_vm.$store.state.vuetify.dialog.overlayOpacity,"overlay-color":_vm.$store.state.vuetify.dialog.overlayColor,"width":"376","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-body"},[_c('modal-header',{attrs:{"text":"Create project"},on:{"closeModal":_vm.closeModal}}),_vm._v(" "),_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"createModal",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Project name*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"name","name":"Project name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","solo":"","dense":"","autofocus":"","error-messages":errors},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}})]}}])})],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Project color")]),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"close-on-click":true,"offset-y":"","nudge-left":28},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"h-10 inline-flex items-center text-xs w-full rounded-md bg-gray-300 dark:bg-dark-800 px-4"},on),[_c('span',{staticClass:"h-6 w-6 rounded-full mr-2",style:(("background-color:" + _vm.projectColor + ";"))}),_vm._v("\n                                "+_vm._s(_vm.projectColor)+"\n                            ")])]}}])},[_vm._v(" "),_c('div',{staticClass:"v-menu-body"},[_c('v-color-picker',{attrs:{"mode":_vm.colorPickerMode,"hide-mode-switch":"","show-swatches":"","swatches-max-height":"200","hide-sliders":""},on:{"update:mode":function($event){_vm.colorPickerMode=$event}},model:{value:(_vm.projectColor),callback:function ($$v) {_vm.projectColor=$$v},expression:"projectColor"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Project thumbnail")]),_vm._v(" "),_c('thumbnail-upload',{staticClass:"mr-auto",on:{"change":_vm.handleThumbnailFileChange}})],1),_vm._v(" "),_c('div',[_c('label',{staticClass:"form-label"},[_vm._v("Producer")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Producer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"user-badges",attrs:{"name":"assigned_to","items":_vm.producerOptions,"item-text":function (item) { return item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); },"error-messages":errors,"menu-props":{ closeOnContentClick: true },"placeholder":"Select a user","solo":"","dense":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(datas){return [_c('user-badge',{attrs:{"user":datas.item,"full-name":"","is-small":""},on:{"click":datas.select}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('user-badge',{attrs:{"user":item,"full-name":""}})]}}],null,true),model:{value:(_vm.producer),callback:function ($$v) {_vm.producer=$$v},expression:"producer"}})]}}])})],1),_vm._v(" "),_c('footer',{staticClass:"dialog-footer"},[_c('div',{staticClass:"col-span-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.closeModal}},[_vm._v("Back")])],1),_vm._v(" "),_c('div',{staticClass:"col-start-3 col-span-3"},[_c('v-btn',{attrs:{"block":"","color":"primary","type":"submit","disabled":!_vm.projectName || !_vm.producer || _vm.postInProgress}},[_c('i',{staticClass:"las la-save mr-2"}),_vm._v("\n                            Save\n                        ")])],1)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }