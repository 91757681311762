<template>
  <div class="card text-center">
    <h1 class="text-6xl font-bold mb-2"><i class="las la-frown" /></h1>
    <h2 class="card-title mb-0 text-center">{{ message }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, required: false, default: 'Nothing here yet!' }
  }
}
</script>
