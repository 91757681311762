<template>
  <p>
    <span v-if="address.country">{{ countries.find(data => data.id === (address.country.id ? address.country.id : address.country)).label }},</span>
    <span v-if="address.zip">{{ address.zip }}.</span>
    <span v-if="address.settlement">{{ address.settlement }},<br></span>
    <span v-if="address.street">{{ address.street }}</span>
    <span v-if="address.houseNumber">{{ address.houseNumber }}.</span>
    <span v-if="!address.zip && !address.settlement && !address.street && !address.houseNumber">-</span>
  </p>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    address: { type: Object, required: true, default: null }
  },
  computed: {
    ...mapGetters({
      countries: 'config/getCountries'
    })
  }
}
</script>
