import Vue from 'vue';

import BaseDescription from '~/components/UI/Base/BaseDescription.vue'
import BaseCheckbox from '~/components/UI/Forms/BaseCheckbox.vue';
import BaseRadio from '~/components/UI/Forms/BaseRadio.vue';
import BaseSwitch from '~/components/UI/Forms/BaseSwitch.vue';
import BaseFileInput from '~/components/UI/Forms/BaseFileInput.vue';
import SelectLoader from '~/components/UI/Forms/SelectLoader.vue';
import DatePicker from '~/components/UI/Forms/DatePicker.vue';
import CkEditor from '~/components/UI/Forms/CkEditor.vue';

import FetchLoader from '~/components/UI/Base/FetchLoader.vue';
import Pagination from '~/components/UI/Base/Pagination.vue';
import Date from '~/components/UI/Base/Date.vue';
import BaseVideoPlayer from '~/components/UI/Base/BaseVideoPlayer.vue';

import UserBadge from '~/components/UI/Users/UserBadge.vue';
import UserGroups from '~/components/UI/Users/UserGroups.vue';
import UserGroupWithDropdown from '~/components/UI/Users/UserGroupWithDropdown';
import UserRoles from '~/components/UI/Users/UserRoles.vue';
import ProjectBadge from '~/components/UI/Projects/ProjectBadge.vue';
import AddressBadge from '~/components/UI/Address/AddressBadge.vue';

import ModalHeader from '~/components/UI/Modals/ModalHeader.vue';
import DeleteModal from '~/components/UI/Modals/DeleteModal.vue';

import GenericError from '~/components/UI/Errors/GenericError.vue';
import EmptyResult from '~/components/UI/Errors/EmptyResult.vue';
import UnderDevelopment from '~/components/UI/Errors/UnderDevelopment.vue';

import GetMessage from '~/components/Messages/Actions/GetMessage.vue';
import SendMessage from '~/components/Messages/Actions/SendMessage.vue';

import NewTask from '~/components/Tasks/NewTask.vue';
import ProjectStatusIndicator from '@/components/UI/Base/ProjectStatusIndicator';
import LinkWithHandIcon from '@/components/UI/Base/LinkWithHandIcon';
import InputError from '@/components/UI/Errors/InputError';
import ActionContextMenu from '@/components/UI/Base/ActionContextMenu';

Vue.component('BaseDescription', BaseDescription);
Vue.component('BaseCheckbox', BaseCheckbox);
Vue.component('BaseRadio', BaseRadio);
Vue.component('BaseSwitch', BaseSwitch);
Vue.component('BaseFileInput', BaseFileInput);
Vue.component('SelectLoader', SelectLoader);
Vue.component('DatePicker', DatePicker);
Vue.component('CkEditor', CkEditor);

Vue.component('FetchLoader', FetchLoader);
Vue.component('Pagination', Pagination);
Vue.component('Date', Date);
Vue.component('BaseVideoPlayer', BaseVideoPlayer);

Vue.component('UserBadge', UserBadge);
Vue.component('UserGroups', UserGroups);
Vue.component('UserGroupWithDropdown', UserGroupWithDropdown);
Vue.component('UserRoles', UserRoles);
Vue.component('ProjectBadge', ProjectBadge);
Vue.component('AddressBadge', AddressBadge);

Vue.component('ModalHeader', ModalHeader);
Vue.component('DeleteModal', DeleteModal);

Vue.component('GenericError', GenericError);
Vue.component('EmptyResult', EmptyResult);
Vue.component('UnderDevelopment', UnderDevelopment);

Vue.component('GetMessage', GetMessage);
Vue.component('SendMessage', SendMessage);
Vue.component('NewTask', NewTask);
Vue.component('ProjectStatusIndicator', ProjectStatusIndicator);
Vue.component('LinkWithHandIcon', LinkWithHandIcon);

Vue.component('InputError', InputError);
Vue.component('ActionContextMenu', ActionContextMenu);
