<template>
    <nav class="logo h-22 flex items-center relative" :class="sidebarState ? '' : 'sidebar-closed w-18'">
        <nuxt-link to="/" class="flex items-center transition-all" :class="sidebarState ? 'py-2 pl-4 pr-4' : 'm-auto'">
            <img :src="logoSource" class="h-6" :class="sidebarState ? 'mr-3' : ''" alt="" />
        </nuxt-link>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            logo: null,
        }
    },
    computed: {
        ...mapGetters({
            sidebarState: 'preferences/getSidebarState',
        }),
        logoSource() {
            return `${process.env.backendURL}/assets/system/logo_${this.sidebarState ? 'full' : 'short'}.svg`;
        },
    },
};
</script>

<style lang="postcss" scoped>
svg {
    @apply dark:text-white;

    .logo-letter {
        @apply transition-all opacity-100;
        @apply fill-current;
    }
}

.sidebar-closed {
    .toggle-sidebar {
        @apply right-40 opacity-0;
    }

    svg {
        .logo-letter {
            &:not(.letter-01) {
                @apply opacity-0;
            }
        }
    }
}
</style>
