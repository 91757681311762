var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-status-indicator inline-flex items-center justify-start w-6 h-6 min-w-6 min-h-6"},[_c('span',{staticClass:"text-xs w-2 h-2 min-w-2 min-h-2 rounded-full relative",class:{
            'bg-green text-green': _vm.status === 'in_progress',
            'bg-system-purple text-system-purple': _vm.status === 'preproduction',
            'bg-red-light text-red-light': _vm.status === 'closed',
            'bg-gray-600 text-gray-600': _vm.status && (_vm.status === 'canceled' || _vm.status.includes('on_hold')),
            'bg-yellow text-yellow': _vm.status === 'ready_to_archive',
            'bg-dark-750 text-dark-750': _vm.status === 'archived' || _vm.status === 'restoration_in_progress',
        }},[_c('span',{staticClass:"project-status-indicator__label absolute top-1/2 -translate-y-1/2 left-5 bg-gray-700 border border-gray-600 z-5 whitespace-nowrap p-1 rounded pointer-events-none transition-opacity"},[_vm._v("\n            "+_vm._s(_vm.label)+"\n        ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }