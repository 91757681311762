<template>
  <div class="VueTables__limit-field flex max-w-32 items-center mr-6 rounded-md pl-3 pr-1">
    <label class="form-label mb-0 mr-2" :for="`VueTables__limit_${props.id}`">
      <span class="font-gotham-400">Show:</span>
    </label>
    <v-select
      :id="props.selectAttrs.id"
      class="small"
      solo dense
      :items="[10, 25, 50, 100]"
      :class="props.selectAttrs.class"
      :value="props.selectAttrs.value"
      @change="props.selectEvents.change"
    />
  </div>
</template>

<script>
export default {
    props: {
        props: { type: Object, required: true },
    },
}
</script>
