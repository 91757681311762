<template>
    <div>
        <div
            v-if="props.totalPages > 1"
            class="VuePagination row col-md-12 justify-content-center"
        >
            <nav class="text-center">
                <ul class="VuePagination__pagination pagination ">
                    <li
                        class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-page cursor-pointer"
                        :class="{'disabled': props.page <= 1}"
                        @click="page(props.page - 1)"
                    >
                        <a class="page-link">&lt;</a>
                    </li>
                    <li
                        v-for="p in displayedPages"
                        :key="p"
                        class="VuePagination__pagination-item page-item cursor-pointer"
                        :class="{'active': p === props.page}"
                        @click="page(p)"
                    >
                        <a class="page-link">{{ p }}</a>
                    </li>
                    <li
                        class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-page cursor-pointer"
                        :class="{'disabled': props.page >= props.totalPages}"
                        @click="page(props.page + 1)"
                    >
                        <a class="page-link">&gt;</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        props: { type: Object, required: true },
    },
    data() {
        return {
            pagesToDisplay: 9,
            displayedPages: [1],
        }
    },
    watch: {
        'props.page'() {
            this.generatePages();
        },
        'props.totalPages'() {
            this.generatePages();
        }
    },
    methods: {
        page(page) {
            this.props.setPage(page);
        },
        generatePages() {
            // The number of page numbers that should appear prior to the current page
            const hL = Math.floor(this.pagesToDisplay / 2.0);
            // The number of page numbers that should appear after the current page
            const hU = Math.ceil(this.pagesToDisplay / 2.0) - 1;

            // Array of displayed page numbers
            this.displayedPages = [];

            // Calculate the starting page, taking various limits into account
            let i = Math.max(Math.min(this.props.page - hL, this.props.totalPages - hL - hU), 1);

            // Generate page numbers starting from the initial page, up to and NOT past the last possible page
            while (true) {
                this.displayedPages.push(i);
                i++;
                if (((i > this.props.page + hU) && this.displayedPages.length >= (hL + hU + 1)) || (i > this.props.totalPages)) {
                    break;
                }
            }
        }
    },
}
</script>

<style scoped>
.VuePagination__pagination-item:hover {
    cursor: pointer;
}
</style>
