import Vue from 'vue';

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, confirmed, min, max } from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});

extend('email', {
    ...email,
    message: 'Email must be valid',
});

extend('confirmed', {
    ...confirmed,
    message: 'Passwords dont match',
});

extend('min', {
    ...min,
    message: '{_field_} must be greater than {length} characters',
});

extend('max', {
    ...max,
    message: '{_field_} must be fewer than {length} characters',
});
