<template>
    <div class="z-20">
        <transition name="fade">
            <div
                v-if="taskVisibility"
                class="backdrop fixed top-0 left-0 w-screen h-screen z-30 bg-black/40"
            />
        </transition>

        <transition name="slide_from_right--large">
            <div
                v-if="taskVisibility"
                class="overflow-scroll fixed top-0 right-0 w-3/4 bg-white dark:bg-dark-850 h-screen z-30 p-8"
            >
                <div class="h-full pb-28">
                    <task-details
                        class="block pb-32"
                        :task-data="newTaskData"
                        @updateTaskVisibility="updateTaskVisibility"
                        @watchTaskData="watchTaskData"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TaskDetails from '~/components/Tasks/TaskDetails.vue';

export default {
    components: {
        TaskDetails,
    },
    props: {
        taskVisibility: { type: Boolean, required: false, default: false },
        versionData: { type: Object, required: false, default: null },
    },
    data() {
        return {
            projects: [],
            users: [],
            task_types: [],
            taskData: {
                name: '',
                status: 1,
                dateStart: '',
                dateEnd: '',
                description: '',
            },
            savedTaskData: null,
        };
    },
    computed: {
        ...mapGetters({
            taskProjectId: 'tasks/getTaskProjectId',
            taskType: 'tasks/getTaskType',
            taskEntityID: 'tasks/getTaskEntityID',
            taskStartDate: 'tasks/getTaskStartDate',
            taskEndDate: 'tasks/getTaskEndDate',
            taskAssignee: 'tasks/getTaskAssignee',
            taskProject: 'tasks/getTaskProject',
            taskSubmit: 'tasks/getTaskSubmit',
            taskSequence: 'tasks/getTaskSequence',
        }),
        newTaskData() {
            return {
                ...this.taskData,
                project: { id: this.taskProject },
                entity: { id: this.taskEntityID },
                type: { id: this.taskType },
                dateStart: this.taskStartDate,
                dateEnd: this.taskEndDate,
                assignee: this.taskAssignee,
                sequenceId: this.taskSequence,
                submitId: this.taskSubmit,
            };
        },
    },
    watch: {
        taskVisibility(e) {
            if (e === false) {
                this.$store.commit('tasks/resetTask');
                this.$unlockScroll();
            } else {
                this.$lockScroll();
            }
        },
    },
    methods: {
        updateTaskVisibility(value) {
            this.$store.commit('tasks/setShowNewTask', value);
            this.$store.commit('tasks/setSavedTaskName', this.savedTaskData.name);
            this.$store.commit('tasks/setSavedTaskDescription', this.savedTaskData.description);
        },
        watchTaskData(data) {
            this.savedTaskData = data;
        },
        closeTask() {
            this.$store.commit('tasks/setShowNewTask', false);
            this.$store.commit('tasks/setSavedTaskName', '');
            this.$store.commit('tasks/setSavedTaskDescription', '');
        },
    },
};
</script>
