<template>
    <vue-plyr :options="options">
        <audio controls crossorigin playsinline>
            <source :src="src" type="audio/mp3" />
        </audio>
    </vue-plyr>
</template>

<script>
export default {
    name: 'BaseAudioPlayer',
    props: {
        submit: {
            type: Object,
            required: true,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            options: {
                controls: !this.small
                    ? [
                          'play-large',
                          'play',
                          'progress',
                          'current-time',
                          'mute',
                          'volume',
                          'captions',
                          'settings',
                          'pip',
                          'airplay',
                          'fullscreen',
                      ]
                    : ['play', 'progress', 'mute', 'volume', 'fullscreen'],
            },
        };
    },
    computed: {
        src() {
            return `${process.env.prodAPI}/submits/${this.submit.id}/media?token=${this.$store.state.auth.token}&v=${new Date().getTime()}`;
        },
    },
};
</script>

<style></style>
