<template>
    <nuxt-link
        v-if="link && $checkPermission('project.read')"
        :to="href ? href : `/projects/${project.id}`"
        class="font-gotham-700 text-xs flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
        :class="{'w-full overflow-hidden overflow-ellipsis': truncate}"
    >
        <project-badge-inside
            :project="project"
            :additional-classes="additionalClasses"
            :is-small="isSmall"
            :icon-only="iconOnly"
            :large="large"
            :link="link"
        />
    </nuxt-link>
    <div v-else :class="{'w-full overflow-hidden overflow-ellipsis': truncate}">
        <project-badge-inside
            :project="project"
            :additional-classes="additionalClasses"
            :is-small="isSmall"
            :icon-only="iconOnly"
            :large="large"
        />
    </div>
</template>

<script>
import ProjectBadgeInside from '~/components/UI/Projects/ProjectBadgeInside.vue';
export default {
    components: {
        ProjectBadgeInside,
    },
    props: {
        project: { type: Object, required: true },
        additionalClasses: { type: String, required: false, default: '' },
        isSmall: { type: Boolean, required: false, default: false },
        iconOnly: { type: Boolean, required: false, default: false },
        link: { type: Boolean, required: false, default: false },
        href: { type: String, required: false, default: null },
        large: { type: Boolean, required: false, default: false },
        truncate: { type: Boolean, required: false, default: false },
    },
};
</script>
