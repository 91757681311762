<template>
    <div :class="state.pending ? 'flex w-max h-max' : ''">
        <h2 v-if="state.pending" class="m-auto" :class="card ? 'card-title' : 'text-center'">
            <i class="animate-spin las la-circle-notch" style="font-size: 4em !important;"/>
        </h2>
        <div v-else-if="state.error">
            <div v-if="input" class="h-10 w-full bg-gray-150 text-xs flex items-center rounded-md px-4 text-red">
                {{ customErrorMessage || `${state.error.statusCode}: ${state.error.message}` }}
            </div>
            <generic-error v-else :error="state.error" :custom-error-message="customErrorMessage" :card="cardSkin" class="text-center" />
        </div>
        <div v-else>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        state: { type: Object, required: true, default: null },
        card: { type: Boolean, required: false, default: false },
        input: { type: Boolean, required: false, default: false },
        cardSkin: { type: Boolean, required: false, default: false },
        customErrorMessage: { type: String, required: false, default: '' },
        fourOhFourMessage: { type: String, required: false, default: '' }
    }
}
</script>
