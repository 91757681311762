var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":_vm.$store.state.vuetify.dialog.overlayOpacity,"overlay-color":_vm.$store.state.vuetify.dialog.overlayColor,"width":"540","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-body"},[_c('modal-header',{attrs:{"text":"Send message","icon":"la-paper-plane"},on:{"closeModal":_vm.closeModal}}),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(/* Recipients */)+"\n                "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Send to*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"recipients","name":"Assignee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.users && _vm.me)?_c('v-autocomplete',{staticClass:"user-badges",attrs:{"name":"assigned_to","items":_vm.sortedUsers,"item-text":function (item) { return item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); },"error-messages":errors,"menu-props":{ closeOnContentClick: true },"label":"Send message to","multiple":"","solo":"","dense":"","chips":"","return-object":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(datas){return [_c('v-chip',_vm._b({attrs:{"input-value":datas.selected,"close":""},on:{"click":datas.select,"click:close":function($event){return _vm.removeItem(datas.item)}}},'v-chip',datas.attrs,false),[_c('user-badge',{attrs:{"user":datas.item,"is-small":"","full-name":""}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('user-badge',{attrs:{"user":item,"full-name":""}})]}}],null,true),model:{value:(_vm.message.assigned_to),callback:function ($$v) {_vm.$set(_vm.message, "assigned_to", $$v)},expression:"message.assigned_to"}}):_vm._e()]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"my-4"},[_c('base-switch',{attrs:{"label":"Send emails","name":"send_emails_switch","value":_vm.message.sendEmails},on:{"input":function (val) { _vm.message.sendEmails = val; }}})],1)],1),_vm._v("\n\n                "+_vm._s(/* Selections */)+"\n                "),_c('div',{staticClass:"col-span-6 grid grid-cols-8 gap-y-6 gap-x-4 mb-4"},[_vm._v("\n                    "+_vm._s(/* Type */)+"\n                    "),_c('div',{staticClass:"col-span-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Type*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"entityId","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","required":"","items":_vm.messageTypes,"item-text":function (item) { return item.label; },"error-messages":errors},on:{"input":function($event){return _vm.handleChange('type')}},model:{value:(_vm.selections.type),callback:function ($$v) {_vm.$set(_vm.selections, "type", $$v)},expression:"selections.type"}})]}}],null,true)})],1),_vm._v("\n\n                    "+_vm._s(/* Project */)+"\n                    "),(_vm.selections.type.id > 1)?_c('div',{staticClass:"col-span-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Project*")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"name":"Project","items":_vm.entities.projects,"item-text":function (item) { return item.name; },"error-messages":errors,"menu-props":{ closeOnContentClick: true },"solo":"","dense":"","clearable":"","return-object":""},on:{"input":function($event){return _vm.handleChange('project')}},scopedSlots:_vm._u([{key:"selection",fn:function(datas){return [(!_vm.loading.project)?_c('project-badge',{attrs:{"project":datas.item,"is-small":"","truncate":""},on:{"click":datas.select}}):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('project-badge',{attrs:{"project":item,"is-small":""}})]}}],null,true),model:{value:(_vm.selections.project),callback:function ($$v) {_vm.$set(_vm.selections, "project", $$v)},expression:"selections.project"}})]}}],null,true)}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.project)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Bid */)+"\n                    "),(_vm.selections.type.id === 3)?_c('div',{staticClass:"col-span-8"},[_c('validation-provider',{attrs:{"name":"Bid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Bid*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.bids,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.bid)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                        "+_vm._s(item.name)+"\n                                    ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.bid),callback:function ($$v) {_vm.$set(_vm.selections, "bid", $$v)},expression:"selections.bid"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.bid)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* ResourceType */)+"\n                    "),(_vm.selections.type.id === 4)?_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Resource Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Resource type*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.resourceTypes,"error-messages":errors},on:{"input":function($event){return _vm.handleChange('resourceType')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.resourceType)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                        "+_vm._s(item.name)+"\n                                    ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.resourceType),callback:function ($$v) {_vm.$set(_vm.selections, "resourceType", $$v)},expression:"selections.resourceType"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.resourceType)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Resource (version) */)+"\n                    "),(_vm.selections.type.id === 4)?_c('div',{staticClass:"col-span-2"},[_c('validation-provider',{attrs:{"name":"Resource"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Version*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.resources,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.resource)?_c('span',[_vm._v(_vm._s(("v" + (item.version))))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(("v" + (item.version)))+"\n                                ")]}}],null,true),model:{value:(_vm.selections.resource),callback:function ($$v) {_vm.$set(_vm.selections, "resource", $$v)},expression:"selections.resource"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.resource)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Daily */)+"\n                    "),(_vm.selections.type.id === 6)?_c('div',{staticClass:"col-span-8"},[_c('validation-provider',{attrs:{"name":"Daily"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Daily*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.dailies,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.daily)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                            "+_vm._s(((item.submit.pass.name) + " v" + (item.clientVersion) + " (" + (item.preset) + ") #" + (item.id)))+"\n                                        ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(((item.submit.pass.name) + " v" + (item.clientVersion) + " (" + (item.preset) + ") #" + (item.id)))+"\n                                ")]}}],null,true),model:{value:(_vm.selections.daily),callback:function ($$v) {_vm.$set(_vm.selections, "daily", $$v)},expression:"selections.daily"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.daily)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Broadcast */)+"\n                    "),(_vm.selections.type.id === 7)?_c('div',{staticClass:"col-span-8"},[_c('validation-provider',{attrs:{"name":"Broadcast"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Broadcast*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.broadcasts,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.broadcast)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                            "+_vm._s(((item.submit.pass.name) + " - " + (item.filmCode) + " (" + (item.preset.name) + ") #" + (item.id)))+"\n                                        ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(((item.submit.pass.name) + " - " + (item.filmCode) + " (" + (item.preset.name) + ") #" + (item.id)))+"\n                                ")]}}],null,true),model:{value:(_vm.selections.broadcast),callback:function ($$v) {_vm.$set(_vm.selections, "broadcast", $$v)},expression:"selections.broadcast"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.broadcast)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Group */)+"\n                    "),([5,8].includes(_vm.selections.type.id))?_c('div',{staticClass:"col-span-4"},[_c('validation-provider',{attrs:{"name":"Sequence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Group*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.sequences,"error-messages":errors},on:{"input":function($event){return _vm.handleChange('sequence')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.sequence)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                        "+_vm._s(item.name)+"\n                                    ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.sequence),callback:function ($$v) {_vm.$set(_vm.selections, "sequence", $$v)},expression:"selections.sequence"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.sequence)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Shot */)+"\n                    "),([5,8].includes(_vm.selections.type.id))?_c('div',{staticClass:"col-span-4"},[_c('validation-provider',{attrs:{"name":"Shot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Shot*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.shots,"error-messages":errors},on:{"input":function($event){return _vm.handleChange('shot')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"selection-wrap"},[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.shot),callback:function ($$v) {_vm.$set(_vm.selections, "shot", $$v)},expression:"selections.shot"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.shot)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Pass type */)+"\n                    "),(_vm.selections.type.id === 8)?_c('div',{staticClass:"col-span-2"},[_c('validation-provider',{attrs:{"name":"Pass type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Pass type*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","items":_vm.entities.passTypes,"error-messages":errors},on:{"input":function($event){return _vm.handleChange('passType')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.charAt(0).toUpperCase() + item.slice(1))+"\n                                ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.charAt(0).toUpperCase() + item.slice(1))+"\n                                ")]}}],null,true),model:{value:(_vm.selections.passType),callback:function ($$v) {_vm.$set(_vm.selections, "passType", $$v)},expression:"selections.passType"}})]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Pass */)+"\n                    "),(_vm.selections.type.id === 8)?_c('div',{staticClass:"col-span-4"},[_c('validation-provider',{attrs:{"name":"Pass"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Pass*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.passes,"error-messages":errors},on:{"input":function($event){return _vm.handleChange('pass')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.pass)?_c('span',{staticClass:"selection-wrap"},[_vm._v("\n                                        "+_vm._s(item.name)+"\n                                    ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.pass),callback:function ($$v) {_vm.$set(_vm.selections, "pass", $$v)},expression:"selections.pass"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.pass)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Submit */)+"\n                    "),(_vm.selections.type.id === 8)?_c('div',{staticClass:"col-span-2"},[_c('validation-provider',{attrs:{"name":"Submit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Version*")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","clearable":"","return-object":"","items":_vm.entities.submits,"error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(!_vm.loading.submit)?_c('span',[_vm._v(_vm._s(("v" + (item.version))))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(("v" + (item.version)))+"\n                                ")]}}],null,true),model:{value:(_vm.selections.submit),callback:function ($$v) {_vm.$set(_vm.selections, "submit", $$v)},expression:"selections.submit"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.submit)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Task type */)+"\n                    "),(_vm.selections.type.id === 9)?_c('div',{staticClass:"col-span-2"},[_c('validation-provider',{attrs:{"name":"Task type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Task type")]),_vm._v(" "),_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","required":"","items":_vm.taskTypes,"item-text":function (item) { return item.label; },"error-messages":errors},on:{"input":function($event){return _vm.handleChange('taskType')}},model:{value:(_vm.selections.taskType),callback:function ($$v) {_vm.$set(_vm.selections, "taskType", $$v)},expression:"selections.taskType"}})]}}],null,true)})],1):_vm._e(),_vm._v("\n\n                    "+_vm._s(/* Task */)+"\n                    "),(_vm.selections.type.id === 9)?_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Task"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label"},[_vm._v("Task*")]),_vm._v(" "),_c('v-autocomplete',{attrs:{"name":"Task","items":_vm.entities.tasks,"item-text":function (item) { return item.name; },"error-messages":errors,"menu-props":{ closeOnContentClick: true },"solo":"","dense":"","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(datas){return [(!_vm.loading.task)?_c('span',{staticClass:"selection-wrap",on:{"click":datas.select}},[_vm._v("\n                                        "+_vm._s(datas.item.name)+"\n                                    ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                                    "+_vm._s(item.name)+"\n                                ")]}}],null,true),model:{value:(_vm.selections.task),callback:function ($$v) {_vm.$set(_vm.selections, "task", $$v)},expression:"selections.task"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.loading.task)?_c('div',{staticClass:"relative h-0 z-10 flex items-center ml-4 -mt-5 mb-5"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1):_vm._e()]),_vm._v("\n\n                "+_vm._s(/* Message */)+"\n                "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Message*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"payload","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.allowCkeditor)?_c('ck-editor',{attrs:{"slim":"","set-focus":"","error-messages":errors},model:{value:(_vm.message.message),callback:function ($$v) {_vm.$set(_vm.message, "message", $$v)},expression:"message.message"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('footer',{staticClass:"dialog-footer"},[_c('div',{staticClass:"col-span-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1),_vm._v(" "),_c('div',{staticClass:"col-start-3 col-span-3"},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":invalid || !_vm.canSend,"type":"submit"}},[_c('i',{staticClass:"las la-paper-plane mr-2"}),_vm._v("\n                            Send\n                        ")])],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }