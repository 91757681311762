export const state = () => ({
    isAssignTaskModalVisible: false,
    selectedCalendarView: { value: 'resourceTimelineWeekFluidShort', text: 'Hours' },
});

export const getters = {};

export const mutations = {
    setSelectedCalendarView(state, payload) {
        state.selectedCalendarView = payload;
    },
};
