<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="376"
        persistent
    >
        <div class="dialog-body">
            <modal-header text="Create project" @closeModal="closeModal" />

            <validation-observer ref="observer">
                <v-form ref="createModal" @submit.prevent="handleSubmit">
                    <div class="mb-4">
                        <label class="form-label">Project name*</label>
                        <validation-provider v-slot="{ errors }" vid="name" name="Project name">
                            <v-text-field
                                v-model="projectName"
                                required
                                solo
                                dense
                                autofocus
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </div>

                    <div class="mb-4">
                        <label class="form-label">Project color</label>
                        <v-menu :close-on-content-click="false" :close-on-click="true" offset-y :nudge-left="28">
                            <template #activator="{ on }">
                                <div
                                    class="h-10 inline-flex items-center text-xs w-full rounded-md bg-gray-300 dark:bg-dark-800 px-4"
                                    v-on="on"
                                >
                                    <span
                                        class="h-6 w-6 rounded-full mr-2"
                                        :style="`background-color:${projectColor};`"
                                    />
                                    {{ projectColor }}
                                </div>
                            </template>
                            <div class="v-menu-body">
                                <v-color-picker
                                    v-model="projectColor"
                                    :mode.sync="colorPickerMode"
                                    hide-mode-switch
                                    show-swatches
                                    swatches-max-height="200"
                                    hide-sliders
                                />
                            </div>
                        </v-menu>
                    </div>
                    <div class="mb-4">
                        <label class="form-label">Project thumbnail</label>
                        <thumbnail-upload class="mr-auto" @change="handleThumbnailFileChange" />
                    </div>

                    <div>
                        <label class="form-label">Producer</label>
                        <validation-provider v-slot="{ errors }" name="Producer">
                            <v-autocomplete

                                v-model="producer"
                                name="assigned_to"
                                :items="producerOptions"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :error-messages="errors"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                placeholder="Select a user"
                                solo
                                dense
                                return-object
                            >
                                <template #selection="datas">
                                    <user-badge :user="datas.item" full-name is-small @click="datas.select" />
                                </template>
                                <template #item="{ item }">
                                    <user-badge :user="item" full-name />
                                </template>
                            </v-autocomplete>
                        </validation-provider>
                    </div>

                    <footer class="dialog-footer">
                        <div class="col-span-2">
                            <v-btn block @click="closeModal">Back</v-btn>
                        </div>
                        <div class="col-start-3 col-span-3">
                            <v-btn
                                block
                                color="primary"
                                type="submit"
                                :disabled="!projectName || !producer || postInProgress"
                            >
                                <i class="las la-save mr-2" />
                                Save
                            </v-btn>
                        </div>
                    </footer>
                </v-form>
            </validation-observer>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ThumbnailUpload from '@/components/UI/Forms/ThumbnailUpload';

export default {
    components: {
        ThumbnailUpload,
    },
    props: {
        showModal: { type: Boolean, required: false },
    },
    data() {
        return {
            projectName: '',
            projectColor: '',
            thumbnailFile: null,
            producer: '',
            colorPickerMode: 'hexa',
            postInProgress: false,
        };
    },
    computed: {
        ...mapGetters({
            users: 'config/getUsers',
            getMe: 'users/me/getMe',
        }),
        dialog: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.closeModal(value);
            },
        },
        producerOptions() {
            return this.users.filter((user) => ['ROLE_ADMIN', 'ROLE_PRODUCER', 'ROLE_SUB_PRODUCER'].some((x) => user.roles.includes(x)));
        },
    },
    watch: {
        dialog(e) {
            if (e === true) {
                setTimeout(() => {
                    this.$refs.observer.reset();
                });
                this.projectColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                this.producer = this.getMe;
            } else {
                this.$nuxt.$emit('clearNewProjectThumbnailFile');
                this.projectName = '';
                this.thumbnailFile = null;
            }
        },
    },
    methods: {
        closeModal() {
            this.$store.commit('project/setNewProjectModalVisibility', false);
        },
        handleSubmit() {
            this.postInProgress = true;
            const postData = new FormData();

            postData.append('name', this.projectName);
            postData.append('color', this.projectColor);
            postData.append('thumbnailFile', this.thumbnailFile);
            postData.append('producer', this.producer.id);

            this.$axios
                .$post(`${process.env.prodAPI}/projects`, postData)
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.closeModal();
                    this.$router.push(`/projects/${res.result.id}/preproduction`);
                })
                .catch((err) => {
                    this.$refs.observer.setErrors(this.$formatErrorMessages(err.response.data.errors));
                })
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        handleThumbnailFileChange(file) {
            this.thumbnailFile = file;
        },
    },
};
</script>
