import { render, staticRenderFns } from "./BaseDescription.vue?vue&type=template&id=75d56270&scoped=true&"
import script from "./BaseDescription.vue?vue&type=script&lang=js&"
export * from "./BaseDescription.vue?vue&type=script&lang=js&"
import style0 from "./BaseDescription.vue?vue&type=style&index=0&id=75d56270&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d56270",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VDialog})
