var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{ref:"headerMessagesMenu",attrs:{"offset-y":"","open-on-focus":"","close-delay":_vm.hangTime,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","medium":"","title":"Messages"},on:{"mouseenter":_vm.openMenu,"mouseleave":_vm.closeMenu,"click":_vm.iconClick}},on),[_c('i',{staticClass:"las la-envelope"}),_vm._v(" "),(_vm.newMessages)?_c('span',{staticClass:"absolute top-0 right-0 border-3 border-gray-300 dark:border-dark-800 bg-red text-white rounded-full w-4 h-4 flex items-center justify-center"}):_vm._e()])]}}])},[_vm._v(" "),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () {
            if (!_vm.justOpened && (!_vm.currentView || !_vm.currentView[0]._data.dialog)) {
                _vm.$refs.headerMessagesMenu._data.isActive = false;
            } else {
                _vm.justOpened = false;
            }
        }),expression:"() => {\n            if (!justOpened && (!currentView || !currentView[0]._data.dialog)) {\n                $refs.headerMessagesMenu._data.isActive = false;\n            } else {\n                justOpened = false;\n            }\n        }"}],staticClass:"v-menu-body",staticStyle:{"padding":"0 0.75rem 0.75rem"},on:{"mouseenter":_vm.openMenu,"mouseleave":_vm.closeMenu}},[_vm._l((_vm.headerMessages),function(msg){return _c('div',{key:msg.id},[_c('message-view-modal',{ref:("msgView-" + (msg.id)),refInFor:true,attrs:{"button-is-text":false,"message":msg,"type":("" + (msg.type)),"button-classes":"items-start","use-anchor":""}},[_c('div',{staticClass:"flex flex-wrap items-center justify-start border-b border-gray-600 w-80 py-2 text-white",on:{"click":function($event){_vm.preventClose(); _vm.currentView = _vm.$refs[("msgView-" + (msg.id))];}}},[_c('div',{staticClass:"flex w-max text-xs"},[(!_vm.isSeen(msg))?_c('span',{staticClass:"bg-red text-white rounded-full w-4 h-4",staticStyle:{"margin-top":"0.25rem","margin-right":"0.25rem"}}):_vm._e(),_vm._v(" "),_c('user-badge',{staticClass:"flex ml-auto mr-2",attrs:{"is-small":"","color":"white","short-name":"","user":msg.created_meta}})],1),_vm._v(" "),_c('div',{staticClass:"w-max text-base text-left my-3"},[_vm._v("\n                        "+_vm._s(_vm.formatPayload(msg.payload))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"flex w-max text-xs text-gray-500"},[(msg.project)?_c('span',[_vm._v(_vm._s(msg.project.name))]):_vm._e(),_vm._v(" "),_c('date',{staticClass:"ml-auto",attrs:{"date":msg.created_meta.date}})],1)])])],1)}),_vm._v(" "),_c('div',{staticClass:"flex gap-4 mt-2"},[_c('v-btn',{attrs:{"color":"tertiary","to":"/messages/inbox"}},[_c('span',{staticClass:"px-1"},[_vm._v("Show all")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"tertiary"},on:{"click":function () { return _vm.readAllMessages(); }}},[_vm._v("Mark all read")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }