var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"header fixed flex justify-between items-center px-8 py-2 transition-all h-14 bg-gray-150 dark:bg-dark-900 w-full z-10",class:[!_vm.sidebarState ? 'pl-18' : 'pl-sidebar']},[_c('div',{staticClass:"flex items-center mr-auto ml-10"},[(/^projects-id/.test(_vm.$route.name))?_c('div',{staticClass:"flex items-center"},[_c('project-badge',{attrs:{"project":_vm.activeProject,"large":""}}),_vm._v(" "),(
                        _vm.$checkPermission('project.update') ||
                        _vm.$checkPermission('message.create') ||
                        _vm.$checkPermission('task.create')
                    )?_c('v-menu',{staticClass:"color-picker",attrs:{"close-on-content-click":true,"offset-y":"","transition":"slide-x-transition","z-index":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-auto mr-4 text-2xl",attrs:{"icon":"","x-small":""}},on),[_c('i',{staticClass:"las la-ellipsis-v transition-colors text-gray-600 group-hover:text-secondary dark-group-hover:text-white"})])]}}],null,false,1993255155)},[_vm._v(" "),_c('div',{staticClass:"v-menu-body"},[_c('ActionContextMenu',{attrs:{"custom":[
                                {
                                    permission: _vm.$checkPermission('project.update'),
                                    label: 'Edit Project',
                                    url: ("/projects/" + (_vm.$route.params.id) + "/details"),
                                    icon: 'la-cog',
                                } ],"task":{
                                entityId: +_vm.$route.params.id,
                                entityTypeId: 2,
                            },"message":{
                                entityId: +_vm.$route.params.id,
                                entityTypeId: 2,
                            }}})],1)]):_vm._e()],1):_c('div',[_c('h1',{staticClass:"font-gotham-700 flex items-center"},[(_vm.pageHeader.icon)?_c('i',{staticClass:"w-4 h-4 inline-flex justify-center items-center mr-4",class:_vm.pageHeader.icon}):_vm._e(),_vm._v(" "),_c('a',{attrs:{"href":_vm.pageHeader.link}},[_vm._v(_vm._s(_vm.pageHeader.title))])])]),_vm._v(" "),(/^projects-id/.test(_vm.$route.name))?_c('project-header',{staticClass:"ml-5"}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-end"},[_c('v-btn',{ref:"searchButton",attrs:{"icon":"","medium":""},on:{"click":_vm.toggleSearchVisibility}},[_c('i',{staticClass:"las la-search"})]),_vm._v(" "),_c('header-downloads'),_vm._v(" "),(_vm.$checkPermission('project.read_list') && !_vm.$user.roles.includes('ROLE_ARTIST'))?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","medium":"","to":"/projects","title":"Projects"}},[_c('i',{staticClass:"las la-stream"})]):_vm._e(),_vm._v(" "),(!_vm.$checkPermission('task.create'))?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","medium":"","to":"/me?tab=my_tasks","title":"Tasks"}},[_c('i',{staticClass:"las la-check-square"})]):_vm._e(),_vm._v(" "),(_vm.$checkPermission('task.create'))?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","medium":"","to":"/tasks","title":"Tasks"}},[_c('i',{staticClass:"las la-check-square"})]):_vm._e(),_vm._v(" "),(
                    _vm.$checkPermission(['dispo_entry.read_list', 'user.read_list']) &&
                    !_vm.$user.roles.includes('ROLE_ARTIST')
                )?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","medium":"","to":"/dispo","title":"Dispo"}},[_c('i',{staticClass:"las la-calendar"})]):_vm._e(),_vm._v(" "),(_vm.$checkPermission('note.read_list'))?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","medium":"","to":"/notes","title":"Notes"}},[_c('i',{staticClass:"las la-sticky-note"})]):_vm._e(),_vm._v(" "),_c('header-messages'),_vm._v(" "),_c('header-notifications'),_vm._v(" "),_c('header-user-actions')],1)]),_vm._v(" "),_c('header-search-bar'),_vm._v(" "),_c('keymaps'),_vm._v(" "),_c('div',{staticClass:"dark-mode"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }