<template>
    <div class="flex items-center relative">
        <div class="users" :class="usersWidth">
            <slot />
            <div v-for="(user, index) of users" :key="user.id" class="user">
                <div
                    v-if="index < usersLength"
                    class="user border-3 bg-white dark:bg-dark-850 bg-image"
                    :class="additionalClasses"
                >
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <img :src="getAvatarImage(user.avatar)" alt="" v-on="on" />
                            <div
                                class="w-8 min-w-8 h-8 rounded-full inline-flex items-center justify-center bg-black text-white"
                                v-on="on"
                            >
                                <span class="font-gotham-700 text-white">
                                    {{ (user.first_name ? user.first_name : user.firstName).charAt(0)
                                    }}{{ (user.last_name ? user.last_name : user.lastName).charAt(0) }}
                                </span>
                            </div>
                        </template>
                        <span class="text-xs">
                            {{ user.first_name ? user.first_name : user.firstName }}
                            {{ user.last_name ? user.last_name : user.lastName }}
                        </span>
                    </v-tooltip>
                </div>
            </div>
        </div>
        <span v-if="showMore && users.length > 5" class="text-xs ml-1">+{{ usersLength - 5 }}</span>
    </div>
</template>

<script>
export default {
    props: {
        users: { type: Array, required: true },
        max: { type: Number, required: false, default: 0 },
        additionalClasses: { type: String, required: false, default: 'white' },
        showMore: { type: Boolean, required: false, default: false },
        small: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            usersWidth: 'w-9',
            usersLength: this.max ? (this.max < this.users.length ? this.max : this.users.length) : this.users.length,
        };
    },
    computed: {
        backendURL() {
            return process.env.backendURL;
        },
    },
    watch: {
        users() {
            this.usersWidthCalc();
        },
    },
    mounted() {
        this.usersWidthCalc();
    },
    methods: {
        usersWidthCalc() {
            switch (this.usersLength) {
                case 0:
                    this.usersWidth = 'w-6';
                    break;
                case 1:
                    this.usersWidth = 'w-9';
                    break;
                case 2:
                    this.usersWidth = 'w-12';
                    break;
                case 3:
                    this.usersWidth = 'w-15';
                    break;
                case 4:
                    this.usersWidth = 'w-18';
                    break;
                case 5:
                    this.usersWidth = 'w-22';
                    break;
                default:
                    this.usersWidth = 'w-9';
                    break;
            }
            if (this.usersLength >= 6) {
                this.usersWidth = 'w-22';
            }
        },
        firstName(user) {
            return user.first_name ? user.first_name : user.firstName;
        },
        lastName(user) {
            return user.last_name ? user.last_name : user.lastName;
        },
    },
};
</script>

<style lang="postcss" scoped>
.users {
    @apply relative h-8;

    .user {
        @apply w-5 h-5 p-4 flex items-center justify-center;
        @apply absolute top-1/2 left-0 transform -translate-y-1/2;
        @apply text-gray-300 rounded-full;
        @apply opacity-0;
        z-index: 0;

        &:nth-child(1) {
            @apply left-0 opacity-100;
            z-index: 7;
        }

        &:nth-child(2) {
            @apply left-3 opacity-100;
            z-index: 6;
        }

        &:nth-child(3) {
            @apply left-6 opacity-100;
            z-index: 5;
        }

        &:nth-child(4) {
            @apply left-9 opacity-100;
            z-index: 4;
        }

        &:nth-child(5) {
            @apply left-12 opacity-100;
            z-index: 3;
        }

        /* &:nth-child(6) {
      @apply left-15;
      z-index: 2;
    }

    &:nth-child(7) {
      @apply left-18;
      z-index: 1;
    } */
    }
}
</style>
