export const state = () => ({
    sidebarAdminOpen: true,
    searchVisibility: false,
    fullScreen: false,
    pageHeader: {
        title: 'Pussy',
        icon: null,
    },
    dayTime: '',
});

export const getters = {
    getSidebarAdminState(state) {
        return state.sidebarAdminOpen;
    },
    getSearchVisibility(state) {
        return state.searchVisibility;
    },
    getFullScreenState(state) {
        return state.fullScreen;
    },
    getPageHeader(state) {
        return state.pageHeader;
    },
    getDayTime(state) {
        return state.dayTime;
    },
};

export const mutations = {
    setSidebarAdminState(state, payload) {
        if (payload === false) {
            state.sidebarAdminOpen = false;
        } else {
            state.sidebarAdminOpen = payload || !state.sidebarAdminOpen;
        }
    },
    toggleSearchVisibility(state) {
        state.searchVisibility = !state.searchVisibility;
    },
    setPageHeader(state, payload) {
        state.pageHeader.title = payload.title;
        state.pageHeader.icon = payload.icon;
        state.pageHeader.link = payload.href;
    },
    setDayTime(state, dayTime) {
        state.dayTime = dayTime;
    },
};
