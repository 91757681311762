const getInitialState = () => ({
    activeProject: {},
    activeShot: {},
    activePasses: {},
    sequences: [],
    team: [],
    isNewProjectModalOpen: false,
    newColorSpace: null,
    fetchTimes: {},
    navigating: false,
    loadedObjects: {},
});

export const state = () => getInitialState();

export const getters = {
    getTeam(state) {
        return state.team;
    },
    getActiveProjectSequences(state) {
        return state.sequences;
    },
    getActiveProject(state) {
        return state.activeProject;
    },
    getActiveShot(state) {
        return state.activeShot;
    },
    getActivePasses(state) {
        return state.activePasses;
    },
    getNewColorSpace(state) {
        return state.newColorSpace;
    },
    getNavigating(state) {
        return state.navigating;
    }
};

export const mutations = {
    setActiveProject(state, payload) {
        if (!state.navigating) state.activeProject = payload;
        else state.loadedObjects.activeProject = payload;
    },
    setSequences(state, payload) {
        if (!state.navigating) state.sequences = payload;
        else state.loadedObjects.sequences = payload;
    },
    setActiveShot(state, payload) {
        if (!state.navigating) state.activeShot = payload;
        else state.loadedObjects.activeShot = payload;
    },
    setActivePasses(state, payload) {
        if (!state.navigating) state.activePasses[payload.type] = payload.data;
        else if (state.loadedObjects.activePasses) state.loadedObjects.activePasses[payload.type] = payload.data;
        else {
            state.loadedObjects.activePasses = {};
            state.loadedObjects.activePasses[payload.type] = payload.data;
        }
    },
    setTeam(state, payload) {
        if (!state.navigating) state.team = payload;
        else state.loadedObjects.team = payload;
    },
    setNewProjectModalVisibility(state, payload) {
        state.isNewProjectModalOpen = payload;
    },
    setNewColorSpace(state, payload) {
        state.newColorSpace = payload;
    },
    setFetchTime(state, payload) {
        state.fetchTimes[payload.key] = payload.value;
    },
    setFetchTimes(state, payload) {
        state.fetchTimes = payload;
    },
    setNavigating(state, payload) {
        if (payload === true) {
            state.navigating = true;
        } else {
            state.navigating = false;
            for (const key in state.loadedObjects) {
                state[key] = state.loadedObjects[key];
            }
            state.loadedObjects = {};
        }
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
    setCurrentArchive(state, payload) {
        if (state.activeProject?.currentArchive) {
            state.activeProject.currentArchive.software = payload;
        }
    },
};

export const actions = {
    setAllFetchTimes(store, time) {
        const fetchTimes = JSON.parse(JSON.stringify(store.state.fetchTimes));
        for (const key in store.state.fetchTimes) {
            fetchTimes[key] = time;
        }
        store.commit('setFetchTimes', fetchTimes);
    },
    async fetchActiveProject(store, {id, time}) {
        time = time || (new Date()).getTime();
        store.commit('setFetchTime', {key: 'activeProject', value: time});
        const params = new URLSearchParams();
        params.append('scopes[]', 'with_milestone_info');
        params.append('scopes[]', 'with_resource_categories');
        params.append('scopes[]', 'created_meta');
        params.append('scopes[]', 'updated_meta');
        return await this.$axios
            .$get(`${process.env.prodAPI}/projects/${id}`, { params })
            .then((res) => {
                if (store.state.fetchTimes.activeProject === time) {
                    store.commit('setActiveProject', res.result);
                }
            })
            .catch((e) => {
                const error = new Error(e.response?.data?.message || 'Error while loading data.');
                error.statusCode = e.response?.status || null;
                if (error.statusCode !== null) throw error;
            });
    },
    async fetchActiveProjectMembers(store, {id, time}) {
        time = time || (new Date()).getTime();
        store.commit('setFetchTime', {key: 'activeProjectMembers', value: time});
        return await this.$axios
            .$get(`${process.env.prodAPI}/project-members?filters[]=project:eq:${id}`)
            .then((res) => {
                if (store.state.fetchTimes.activeProjectMembers === time) {
                    store.commit('setTeam', res.result);
                }
            })
            .catch((e) => {
                const error = new Error(e.response?.data?.message || 'Error while loading data.');
                error.statusCode = e.response?.status || null;
                if (error.statusCode !== null) throw error;
            });
    },
    async fetchActiveShot(store, {id, time}) {
        time = time || (new Date()).getTime();
        store.commit('setFetchTime', {key: 'activeShot', value: time});
        return await this.$axios.$get(`${process.env.prodAPI}/shots/${id}?scopes[]=with_members`)
            .then((res) => {
                if (store.state.fetchTimes.activeShot === time) {
                    store.commit('setActiveShot', res.result);
                }
            })
            .catch((e) => {
                if (e.response?.status === 404) {
                    window.$nuxt.$store.commit('routing/setSavedProjectTabRoute', {
                        projectID: window.$nuxt.$route.params.id,
                        tabRoute: null,
                    });
                    this.$router.push(`/projects/${window.$nuxt.$route.params.id}`);
                } else {
                    const error = new Error(e.response?.data?.message || 'Error while loading data.');
                    error.statusCode = e.response?.status || null;
                    if (error.statusCode !== null) throw error;
                }
            });
    },
    async fetchSequences(store, {id, time}) {
        time = time || (new Date()).getTime();
        store.commit('setFetchTime', {key: 'activeSequences', value: time});
        await this.$axios
            .$get(`${process.env.prodAPI}/sequences?filters[]=project:eq:${id}`)
            .then((res) => {
                if (store.state.fetchTimes.activeSequences === time) {
                    store.commit('setSequences', res.result);
                }
            })
            .catch((e) => {
                const error = new Error(e.response?.data?.message || 'Error while loading data.');
                error.statusCode = e.response?.status || null;
                if (error.statusCode !== null) throw error;
            });
    },
    async fetchPasses(store, {id, type, time}) {
        time = time || (new Date()).getTime();
        store.commit('setFetchTime', {key: `activePasses${type}`, value: time});
        await this.$axios
            .$get(
                `${process.env.prodAPI}/passes?scopes[]=with_submits&scopes[]=created_meta&scopes[]=updated_meta&filters[]=shot:eq:${id}&filters[]=type:eq:${type}`
            )
            .then((res) => {
                if (store.state.fetchTimes[`activePasses${type}`] === time) {
                    store.commit('setActivePasses', { type, data: res.result });
                }
            })
            .catch((e) => {
                if (e.response?.status === 404) {
                    // pass
                } else {
                    const error = new Error(e.response?.data?.message || 'Error while loading data.');
                    error.statusCode = e.response?.status || null;
                    if (error.statusCode !== null) throw error;
                }
            });
    },
};
