<template>
  <transition name="fade">
    <div v-if="loaderState" class="absolute top-0 left-0 w-full h-12 bg-dark-850 dark:bg-dark-850 flex justify-between items-center pl-4 pr-3" style="z-index:20;">
      <span class="text-xs text-white">Loading...</span>
      <i class="animate-spin las la-circle-notch text-xl text-white" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    loaderState: { type: Boolean, required: false, default: false }
  }
}
</script>
