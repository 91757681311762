export default function ({ redirect, route, store }) {
    // previously /dispo/users was /dispo/tasks, so this will redirect user to the new route
    // in case they have "tasks" stored in cookie as the last visited dispo tab
    if (/^(dispo-tasks|dispo-tasks-id)$/.test(route.name)) {
        return redirect('/dispo/users');
    }

    if (route.name === 'dispo') {
        if (store.state.routing.lastDispoTab && store.state.routing.lastDispoTab !== 'tasks') {
            return redirect('/dispo/' + store.state.routing.lastDispoTab);
        } else {
            return redirect('/dispo/users');
        }
    }
}
