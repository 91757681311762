<template>
    <div class="search-container relative flex items-center content-end w-full z-50">
        <div class="flex-auto absolute -top-6 right-10 w-60">
            <transition name="fade">
                <div
                    v-if="searchVisibility"
                    class="fixed w-full h-full top-0 left-0 bg-secondary/80 z-40"
                    @click="toggleSearchVisibility"
                />
            </transition>
            <transition name="fade">
                <div v-if="searchVisibility" class="fixed top-0 z-50 left-0 w-full h-22 bg-white dark:bg-dark-850 px-8">
                    <v-text-field
                        ref="bigSearchInput"
                        v-model="bigSearchInput"
                        dense
                        solo
                        autocomplete="off"
                        tabindex="0"
                        placeholder="Search..."
                        @input="search"
                        @keydown.esc="closeSearch"
                    />
                    <i class="las la-search text-4xl absolute top-1/2 -translate-y-1/2 left-8" />
                    <v-btn
                        icon
                        large
                        class="absolute top-1/2 -translate-y-1/2 right-8"
                        tabindex="-1"
                        @click="toggleSearchVisibility"
                    >
                        <i class="las la-times text-4xl" />
                    </v-btn>
                </div>
            </transition>
            <transition name="fade">
                <div
                    v-if="showResponse && bigSearchInput"
                    v-closable="{ exclude: ['searchButton', 'bigSearchInput'], handler: 'focusSearchOut' }"
                    class="response-container fixed left-0 top-22 px-8 w-full bg-white dark:bg-dark-850 z-50 overflow-y-scroll"
                >
                    <div v-for="type in searchTypes" :key="type">
                        <header-search-results
                            v-if="results.find((data) => data.type === type)"
                            :results="results.filter((data) => data.type === type)"
                            @closeSearch="closeSearch"
                        />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderSearchResults from '~/components/Layouts/Header/HeaderSearchResults.vue';
export default {
    components: {
        HeaderSearchResults,
    },
    data() {
        return {
            bigSearchInput: '',
            results: null,
            focusOut: false,
            searchTypes: ['shot', 'project', 'brand', 'client'],
        };
    },
    computed: {
        ...mapGetters({
            searchVisibility: 'getSearchVisibility',
        }),
        showResponse() {
            return !!this.results;
        },
    },
    watch: {
        searchVisibility(e) {
            if (e === false) {
                this.resetSearch();
            } else {
                setTimeout(() => {
                    this.$refs.bigSearchInput.focus();
                });
            }
        },
    },
    created() {
        this.search = this.$_.debounce(this.search, 500);
    },
    methods: {
        resetSearch() {
            this.bigSearchInput = '';
            this.results = null;
        },
        toggleSearchVisibility() {
            this.$store.commit('toggleSearchVisibility');
        },
        search(e) {
            if (e.length >= 3) {
                this.$axios
                    .$get(`${process.env.prodAPI}/search?filters[]=content:lk:${e}&order=content.name`)
                    .then((res) => {
                        this.results = res.result;
                    })
                    .catch((e) => {
                        this.$notify({ title: e.response.data.message, type: 'error' });
                    });
            }
        },
        focusSearchOut() {
            this.$store.commit('toggleSearchVisibility');
            this.resetSearch();
        },
        closeSearch() {
            this.$store.commit('toggleSearchVisibility');
        },
    },
};
</script>

<style>
.response-container {
    max-height: calc(100vh - 5.5rem);
}
</style>
