<template>
  <label :for="name" class="checkbox-skin relative" :class="{'disabled': disabled}">
    <input :id="name" :ref="name" v-model="inputVal" :value="customValue" type="checkbox" :name="name" :disabled="disabled">
    <span><small v-if="label" class="text-xs ml-2">{{ label }}</small></span>
  </label>
</template>

<script>
export default {
  props: {
    customValue: { type: [Boolean, Number], required: false, default: false },
    value: { type: [Boolean, Array], required: true, default: false },
    name: { type: String, required: true, default: 'checkbox' },
    label: { type: String, required: false, default: '' },
    disabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      checkbox: {
        name: 'check_name',
        label: 'check_label',
        disabled: false
      }
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
