<template>
    <div class="flex items-center justify-between mt-6 VueTables" :class="{'gray-bg': grayBackground}">
        <div>
            <div
                v-if="pageNum > 1"
                class="VuePagination row col-md-12 justify-content-center"
            >
                <nav class="text-center">
                    <ul class="VuePagination__pagination pagination ">
                        <li
                            class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-page cursor-pointer"
                            :class="{'disabled': pagination.page <= 1}"
                            @click="page(pagination.page - 1)"
                        >
                            <a class="page-link">&lt;</a>
                        </li>
                        <li
                            v-for="p in displayedPages"
                            :key="p"
                            class="VuePagination__pagination-item page-item cursor-pointer"
                            :class="{'active': p === pagination.page}"
                            @click="page(p)"
                        >
                            <a class="page-link">{{ p }}</a>
                        </li>
                        <li
                            class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-page cursor-pointer"
                            :class="{'disabled': pagination.page >= pageNum}"
                            @click="page(pagination.page + 1)"
                        >
                            <a class="page-link">&gt;</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="inline-flex items-center ml-auto">
            <p class="text-xs mr-8">Number of results {{ pagination.num_items }} / {{ pagination.count }}</p>
            <div class="flex max-w-32 items-center mr-6 rounded-md pl-3 pr-1">
                <label for="VueTables__limit_DepEt" class="form-label mb-0 mr-4"><span class="font-gotham-400">Show: </span></label>
                <v-select
                    v-model="pagination.per_page"
                    :items="[10, 25, 50, 100]"
                    solo dense
                    @input="updatePaginationPerpage"
                />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        pagination: { type: Object, required: true, default() { return { num_items: null, count: null, per_page: 10, page: 1 } } },
        grayBackground: { type: Boolean, required: false, default: false }
    },
    data() {
        return {
            pagesToDisplay: 9,
            displayedPages: [1],
        }
    },
    computed: {
        pageNum() {
            if (this.pagination.num_pages) {
                return this.pagination.num_pages;
            }
            return Math.ceil(this.pagination.count / this.pagination.per_page);
        }
    },
    watch: {
        'pagination.page'() {
            this.generatePages();
        },
        pageNum() {
            this.generatePages();
        }
    },
    methods: {
        updatePaginationPerpage(e) {
            this.$emit('updatePaginationPerpage', e);
        },
        page(e) {
            this.$emit('updatePaginationPage', e);
        },
        generatePages() {
            // The number of page numbers that should appear prior to the current page
            const hL = Math.floor(this.pagesToDisplay / 2.0);
            // The number of page numbers that should appear after the current page
            const hU = Math.ceil(this.pagesToDisplay / 2.0) - 1;

            // Array of displayed page numbers
            this.displayedPages = [];

            // Calculate the starting page, taking various limits into account
            let i = Math.max(Math.min(this.pagination.page - hL, this.pageNum - hL - hU), 1);

            // Generate page numbers starting from the initial page, up to and NOT past the last possible page
            while (true) {
                this.displayedPages.push(i);
                i++;
                if (((i > this.pagination.page + hU) && this.displayedPages.length >= (hL + hU + 1)) || (i > this.pageNum)) {
                    break;
                }
            }
        }
    }
}
</script>
