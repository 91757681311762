export const state = () => ({
    refreshDailiesTable: false,
})

export const getters = {
    getRefreshDailiesTable(state) {
        return state.refreshDailiesTable;
    },
}

export const mutations = {
    setRefreshDailiesTable(state, status) {
        state.refreshDailiesTable = status;
    },
}

export const actions = {
}
