import Vue from 'vue';

import moment from 'moment';

Vue.use(moment);

export default ({ app }) => {
    moment.updateLocale('en', {
        week: {
            dow: 1, // Monday is the first day of the week.
        },
        relativeTime: {
            m: '1m',
            mm: '%dm',
            h: '1h',
            hh: '%dh',
            d: '1d',
            dd: '%dd',
            M: '1mo',
            MM: '%dmo',
            y: '1y',
            yy: '%dy',
        },
    });
};
