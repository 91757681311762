<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="320"
        persistent
    >
        <template #activator="{ on }">
            <v-btn
                :disabled="buttonDisabled"
                :icon="buttonIsIcon"
                :color="buttonColor"
                :left="buttonLeft"
                :block="buttonBlock"
                :text="buttonIsText"
                :fab="buttonIsFab"
                :small="buttonIsSmall"
                :class="buttonClasses"
                title="Delete"
                v-on="on"
            >
                <slot><i class="las la-trash" /></slot>
            </v-btn>
        </template>

        <div class="dialog-body">
            <modal-header text="Are you sure?" @closeModal="closeModal" />

            <p>
                <span v-if="modalText">{{ modalText }}</span>
                <span v-else>This will permanently delete this item.</span>
            </p>

            <footer class="dialog-footer">
                <div class="col-span-2">
                    <v-btn block @click="closeModal">Cancel</v-btn>
                </div>
                <div class="col-start-3 col-span-3">
                    <v-btn block color="danger" :disabled="disabled" @click="onDelete">
                        <span v-if="buttonText">{{ buttonText }}</span>
                        <span v-else><i class="las la-trash mr-2" />Delete</span>
                    </v-btn>
                </div>
            </footer>
        </div>
    </v-dialog>
</template>

<script>
export default {
    props: {
        buttonIsText: { type: Boolean, required: false, default: false },
        buttonBlock: { type: Boolean, required: false, default: false },
        buttonIsIcon: { type: Boolean, required: false, default: false },
        buttonIsFab: { type: Boolean, required: false, default: false },
        buttonIsSmall: { type: Boolean, required: false, default: false },
        buttonColor: { type: String, required: false, default: 'danger' },
        buttonLeft: { type: Boolean, required: false, default: false },
        buttonClasses: { type: String, required: false, default: '' },
        buttonDisabled: {type: Boolean, required: false, default: false},
        disabled: { type: Boolean, required: false, default: false },
        modalText: { type: String, required: false, default: '' },
        buttonText: { type: String, required: false, default: '' },
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        onDelete() {
            this.$emit('deleteData');
            this.closeModal();
        },
        closeModal() {
            this.dialog = false;
        },
    },
};
</script>

<style>
.v-menu-body .v-btn {
    justify-content: flex-start;
    @apply px-2 !important;
}
</style>
