<template>
    <div>
        <header
            class="header fixed flex justify-between items-center px-8 py-2 transition-all h-14 bg-gray-150 dark:bg-dark-900 w-full z-10"
            :class="[!sidebarState ? 'pl-18' : 'pl-sidebar']"
        >
            <div class="flex items-center mr-auto ml-10">
                <div v-if="/^projects-id/.test($route.name)" class="flex items-center">
                    <project-badge :project="activeProject" large />
                    <v-menu
                        v-if="
                            $checkPermission('project.update') ||
                            $checkPermission('message.create') ||
                            $checkPermission('task.create')
                        "
                        class="color-picker"
                        :close-on-content-click="true"
                        offset-y
                        transition="slide-x-transition"
                        z-index="30"
                    >
                        <template #activator="{ on }">
                            <v-btn class="ml-auto mr-4 text-2xl" icon x-small v-on="on">
                                <i
                                    class="las la-ellipsis-v transition-colors text-gray-600 group-hover:text-secondary dark-group-hover:text-white"
                                />
                            </v-btn>
                        </template>
                        <div class="v-menu-body">
                            <ActionContextMenu
                                :custom="[
                                    {
                                        permission: $checkPermission('project.update'),
                                        label: 'Edit Project',
                                        url: `/projects/${$route.params.id}/details`,
                                        icon: 'la-cog',
                                    },
                                ]"
                                :task="{
                                    entityId: +$route.params.id,
                                    entityTypeId: 2,
                                }"
                                :message="{
                                    entityId: +$route.params.id,
                                    entityTypeId: 2,
                                }"
                            />
                        </div>
                    </v-menu>
                </div>
                <div v-else>
                    <h1 class="font-gotham-700 flex items-center">
                        <i
                            v-if="pageHeader.icon"
                            class="w-4 h-4 inline-flex justify-center items-center mr-4"
                            :class="pageHeader.icon"
                        />
                        <a
                            :href="pageHeader.link"
                        >{{ pageHeader.title }}</a>
                    </h1>
                </div>
                <project-header v-if="/^projects-id/.test($route.name)" class="ml-5" />
            </div>

            <div class="flex items-center justify-end">
                <v-btn ref="searchButton" icon medium @click="toggleSearchVisibility">
                        <i class="las la-search" />
                </v-btn>
                <header-downloads />
                <v-btn
                    v-if="$checkPermission('project.read_list') && !$user.roles.includes('ROLE_ARTIST')"
                    icon
                    medium
                    to="/projects"
                    class="ml-2"
                    title="Projects"
                >
                    <i class="las la-stream" />
                </v-btn>
                <v-btn
                    v-if="!$checkPermission('task.create')"
                    icon
                    medium
                    to="/me?tab=my_tasks"
                    class="ml-2"
                    title="Tasks"
                >
                    <i class="las la-check-square" />
                </v-btn>
                <v-btn
                    v-if="$checkPermission('task.create')"
                    icon
                    medium
                    to="/tasks"
                    class="ml-2"
                    title="Tasks"
                >
                    <i class="las la-check-square" />
                </v-btn>
                <v-btn
                    v-if="
                        $checkPermission(['dispo_entry.read_list', 'user.read_list']) &&
                        !$user.roles.includes('ROLE_ARTIST')
                    "
                    icon
                    medium
                    to="/dispo"
                    class="ml-2"
                    title="Dispo"
                >
                    <i class="las la-calendar" />
                </v-btn>
                <v-btn v-if="$checkPermission('note.read_list')" icon medium to="/notes" class="ml-2" title="Notes">
                    <i class="las la-sticky-note" />
                </v-btn>

                <header-messages />

                <header-notifications />

                <header-user-actions />
            </div>
        </header>

        <header-search-bar />

        <keymaps />

        <div class="dark-mode"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import HeaderSearchBar from '@/components/Layouts/Header/HeaderSearchBar.vue';
import HeaderNotifications from '@/components/Layouts/Header/HeaderNotifications.vue';
import HeaderUserActions from '@/components/Layouts/Header/HeaderUserActions.vue';
import HeaderMessages from '@/components/Layouts/Header/HeaderMessages.vue';
import HeaderDownloads from '@/components/Layouts/Header/HeaderDownloads.vue';
import ProjectHeader from '@/components/Layouts/Header/Project/MainProjectHeader.vue';
import Keymaps from '@/components/Shortcuts/Keymaps.vue';

export default {
    name: 'TheHeader',
    components: {
        HeaderSearchBar,
        HeaderNotifications,
        HeaderUserActions,
        HeaderMessages,
        HeaderDownloads,
        ProjectHeader,
        Keymaps,
    },
    computed: {
        ...mapGetters({
            pageHeader: 'getPageHeader',
            sidebarState: 'preferences/getSidebarState',
            activeProject: 'project/getActiveProject',
        }),
    },
    methods: {
        toggleSearchVisibility() {
            this.$store.commit('toggleSearchVisibility');
        },
    },
};
</script>

<style lang="postcss" scoped>
.dark-mode .v-btn.theme--light.v-btn--has-bg {
    @apply bg-dark-850;
}
</style>
