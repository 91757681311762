<template>
  <label class="form-switch" :class="{'disabled': disabled}">
    <input v-model="computedName" :name="name" :value="value" type="radio" :disabled="disabled">
    <span class="form-switch-slider" />
    <span class="text">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    value: { type: [String, Number, Object], required: true, default: '' },
    name: { type: [String, Number, Object], required: true, default: '' },
    label: { type: String, required: false, default: ' ' },
    disabled: { type: Boolean, required: false, default: false }
  },
  computed: {
    computedName: {
      get() {
        return this.name
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
