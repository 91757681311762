const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['autoLogin'] = require('../middleware/autoLogin.js')
middleware['autoLogin'] = middleware['autoLogin'].default || middleware['autoLogin']

middleware['management'] = require('../middleware/management.js')
middleware['management'] = middleware['management'].default || middleware['management']

middleware['permission'] = require('../middleware/permission.js')
middleware['permission'] = middleware['permission'].default || middleware['permission']

middleware['project'] = require('../middleware/project.js')
middleware['project'] = middleware['project'].default || middleware['project']

export default middleware
