export default ({ app, $axios, store }) => {
    $axios.onRequest((config) => {
        config.headers.common['X-AUTH-TOKEN'] = store.state.auth.token;
    });
    $axios.onError((error) => {
        if (error.response.status === 401 &&
            !app.router.history.current.name.includes('login') &&
            !app.router.history.current.name.includes('forgot-password')
        ) {
            store.dispatch('auth/clearTokens');
            app.router.push('/login');
        }
    });
}
