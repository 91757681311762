<template>
    <div class="flex items-center" :class="additionalClasses">
        <slot name="beforeBadge" />
        <span
            v-if="project.color"
            class="relative rounded-full mr-2 font-gotham-700 text-white uppercase inline-flex items-center justify-center bg-cover bg-center"
            :class="
                isSmall
                    ? 'w-5 min-w-5 h-5 max-h-5 text-xxxs'
                    : hasLargeIcon
                    ? 'w-9.5 h-9.5 min-w-9.5'
                    : 'w-8 min-w-8 h-8 max-h-8 text-xs'
            "
            :style="`${!thumbnailSrc ? `background-color:${project.color}` : ''}; ${
                thumbnailSrc ? `background-image: url(${thumbnailSrc})` : ''
            }`"
        >
            {{ !thumbnailSrc ? project.name.slice(0, 2) : '' }}
            <slot name="insideBadge" />
        </span>
        <p v-if="!iconOnly" class="h-full inline-flex">
            <span
                class="cell-content-max-width-20 dark:text-white"
                :class="large ? 'font-gotham-700 block max-w-30vw truncate mr-4' : 'text-xs'"
            >
                {{ project.name }}
            </span>
            <slot name="milestones" />
        </p>
    </div>
</template>

<script>
export default {
    props: {
        project: { type: Object, required: true },
        additionalClasses: { type: String, required: false, default: '' },
        isSmall: { type: Boolean, required: false, default: false },
        hasLargeIcon: { type: Boolean, required: false, default: false },
        iconOnly: { type: Boolean, required: false, default: false },
        large: { type: Boolean, required: false, default: false },
    },
    computed: {
        thumbnailSrc() {
            return this.project.thumbnailPath ? `${process.env.backendURL}/${this.project.thumbnailPath}` : null;
        },
    },
};
</script>
