<template>
    <div class="main login-page relative min-w-screen min-h-screen" data-app>
        <div v-if="+$moment().format('MM') === 12">
            <section class="sleigh">
                <div style="z-index: 100;" class="santa">🦌</div>
                <div style="z-index: 100;" class="santa1">🦌</div>
                <div style="z-index: 100;" class="santa2">🦌</div>
                <div style="z-index: 100;" class="santa3">🦌</div>
                <div style="z-index: 100;" class="santa4">🦌</div>
                <div style="z-index: 100;" class="santa5"><i style="font-size: 48px!important; z-index: 100;" class="las la-sleigh"></i></div>
            </section>
            <section class="snow">
                <div class="snowflake"><i class="las la-snowflake"></i></div>
                <div class="snowflake1"><i class="las la-snowflake"></i></div>
                <div class="snowflake2"><i class="las la-snowflake"></i></div>
                <div class="snowflake3"><i class="las la-snowflake"></i></div>
                <div class="snowflake4"><i class="las la-snowflake"></i></div>
                <div class="snowflake5"><i class="las la-snowflake"></i></div>
                <div class="snowflake6"><i class="las la-snowflake"></i></div>
                <div class="snowflake7"><i class="las la-snowflake"></i></div>
                <div class="snowflake8"><i class="las la-snowflake"></i></div>
                <div class="snowflake9"><i class="las la-snowflake"></i></div>
                <div class="snowflake10"><i class="las la-snowflake"></i></div>
                <div class="snowflake11"><i class="las la-snowflake"></i></div>
                <div class="snowflake12"><i class="las la-snowflake"></i></div>
                <div class="snowflake13"><i class="las la-snowflake"></i></div>
                <div class="snowflake14"><i class="las la-snowflake"></i></div>
            </section>
        </div>
        <div class="login-image absolute top-0 left-0 w-full h-full bg-image z-10">
            <img :src="require(`/assets/img/contents/${selectedBackground.src || ''}`)" alt="" />
        </div>

        <div v-if="serverStatuses" class="absolute bottom-12 right-24 z-10 text-white">
            <span class="status-badge">
                AD
                <span class="indicator" :class="{ active: serverStatuses.activeDirectory }"></span>
            </span>

            <span class="status-badge">
                Afanasy
                <span class="indicator" :class="{ active: serverStatuses.afanasy }"></span>
            </span>

            <span class="status-badge">
                WSS
                <span class="indicator" :class="{ active: serverStatuses.websocket }"></span>
            </span>
        </div>
        <div class="login-container relative h-screen w-screen z-20 flex justify-center items-center">
            <div class="login-top absolute top-0 left-0 w-full pt-9 px-8 flex justify-between items-center">
                <div>
                    <nav class="logo flex items-center">
                        <nuxt-link to="/" class="text-white text-2xl flex items-center">
                            <img :src="logoSource" class="h-8 mr-3 fill-current" alt="" />
                        </nuxt-link>
                    </nav>
                </div>
                <div>
                    <div class="weather text-white text-2xl">
                        <div class="flex h-10 justify-end items-center">
                            <div class="w-10 h-10 inline-flex items-center justify-center text-3xl mr-2">
                                <img :src="weather.icon" alt="" />
                            </div>
                            <p class="text-2xl leading-6">{{ weather.temp }}°C</p>
                        </div>
                        <div class="text-right">
                            <p class="text-2xl capitalize">{{ weather.location }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login-bottom absolute bottom-0 left-0 w-full z-20 pb-10 px-8 flex justify-between items-center">
                <div class="w-6/12">
                    <div class="text-white font-gotham-500 inline-flex items-center">
                        <i class="lab la-imdb text-4xl" />
                        <span class="ml-4">{{ selectedBackground.name }} </span>
                        <span class="inline-block ml-1 font-gotham-400">({{ selectedBackground.year }})</span>
                    </div>
                </div>
                <div class="w-6/12 text-white text-right">
                    <p>{{ currentTime }}</p>
                </div>
            </div>
            <div class="flex w-full items-center justify-center">
                <div class="hidden lg:block w-0 lg:w-1/3 px-16">
                    <p class="text-3xl text-white">
                        "{{ selectedBackground.quote }}"<br />
                        <strong class="font-gotham-700 text-2xl">{{ selectedBackground.author }}</strong>
                    </p>
                </div>
                <div class="w-1/3 min-w-100">
                    <Nuxt />
                </div>
                <div class="w-0 lg:w-1/3"></div>
            </div>
        </div>

        <notifications-wrapper />
    </div>
</template>

<script>
import NotificationsWrapper from '~/components/UI/Notifications/NotificationsWrapper.vue';

export default {
    name: 'GuestLayout',
    components: {
        NotificationsWrapper,
    },
    // middleware: ['autoLogin'],
    data() {
        return {
            weather: {
                location: 'Budapest',
                temp: '',
                icon: null,
            },
            sunset: null,
            serverStatuses: null,
            sunrise: null,
            backgrounds: [
                {
                    name: 'Star Wars',
                    src: 'bg-starwars.png',
                    quote: 'Raaarwh',
                    year: 1977,
                    author: 'Chewbacca',
                },
                {
                    name: 'Spider-Man: Homecoming',
                    src: 'bg-spiderman.jpg',
                    quote: 'With great power comes great responsibility',
                    year: 2017,
                    author: 'Spider-Man',
                },
                {
                    name: 'Pulp Fiction',
                    src: 'bg-pulp.jpg',
                    quote: 'English, motherfucker, do you speak it?',
                    year: 1994,
                    author: 'Jules Winnfield',
                },
                {
                    name: 'Lord of the Rings',
                    src: 'bg-lotr.jpg',
                    quote: 'All we have to decide is what to do with the time that is given us.',
                    year: 2001,
                    author: 'Gandalf',
                },
                {
                    name: 'Aliens',
                    src: 'Alien_2.jpg',
                    quote: 'Get away from her, you bitch',
                    year: 1986,
                    author: 'Ellen Ripley',
                },
                {
                    name: 'Apocalypse now',
                    src: 'Apocalypse_now_2.jpg',
                    quote: 'I love the smell of napalm in the morning ',
                    year: 1979,
                    author: 'Lt. Col. Bill Kilgore',
                },
                {
                    name: 'Apollo 13',
                    src: 'Apollo_13_2.jpg',
                    quote: 'Houston, we have a problem',
                    year: 1995,
                    author: 'Jim Lovell',
                },{
                    name: 'Die Hard',
                    src: 'diehard_2.jpg',
                    quote: 'Yippee-ki-yay, motherfucker',
                    year: 1988,
                    author: 'John McClane',
                },{
                    name: 'Fear and Loathing in Las Vegas',
                    src: 'fearand_2.jpg',
                    quote: 'We were somewhere around Barstow on the edge of the desert when the drugs began to take hold',
                    year: 1998,
                    author: 'Raul Duke',
                },{
                    name: 'Forrest Gump',
                    src: 'forrest_gump_2.jpg',
                    quote: 'My mama always said, life was like a box of chocolates, you never know what you gonna get',
                    year: 1994,
                    author: 'Forrest Gump',
                },{
                    name: 'Godfather',
                    src: 'godfather_2.jpg',
                    quote: 'Some day, and that day may never come, I will call upon you to do a service for me.',
                    year: 1972,
                    author: 'Vito Corleone',
                },{
                    name: 'Inglourious Basterds',
                    src: 'inglorious_2.jpg',
                    quote: 'Gorlami!',
                    year: 2009,
                    author: 'Aldo Raine ',
                },{
                    name: 'Jaws',
                    src: 'Jaws_2.jpg',
                    quote: 'You\'re gonna need a bigger boat',
                    year: 1975,
                    author: 'Chief Brody',
                },{
                    name: 'Lion King',
                    src: 'LionKing_2.jpeg',
                    quote: 'Hakuna Matata',
                    year: 1994,
                    author: 'Timon and Pumbaa',
                },{
                    name: 'Rick and Morty',
                    src: 'rickandmorty_2.jpg',
                    quote: 'wubba lubba dub dub',
                    year: 2013,
                    author: 'Rick Sanchez',
                },{
                    name: 'Sixth Sense',
                    src: 'Sixth_2.jpg',
                    quote: 'I see dead people.',
                    year: 1999,
                    author: 'Cole Sear',
                },{
                    name: 'Le haine',
                    src: 'lehaine.jpeg',
                    quote: 'How you fall doesn\'t matter. It\'s how you land!',
                    year: 1995,
                    author: 'Hubert Koundé',
                },{
                    name: 'The Sopranos',
                    src: 'sopranos.webp',
                    quote: 'Those who want respect, give respect.',
                    year: 1999,
                    author: 'Tony Soprano',
                },{
                    name: 'Snatch',
                    src: 'Snatch_2.jpg',
                    quote: 'Yes, London, you know, fish, chips, cup o tea, bad food, worse weather, Mary fucking Poppins, London.',
                    year: 2000,
                    author: 'Charlie/ Cousin Avi',
                },{
                    name: 'Taxi Driver ',
                    src: 'taxidrive_2.jpg',
                    quote: 'You talkin\' to me? ',
                    year: 1976,
                    author: 'Travis Bickle',
                },{
                    name: 'Tropic Thunder',
                    src: 'Tropic_thunder_2.jpg',
                    quote: 'Everybody knows you never go full retard',
                    year: 2008,
                    author: 'Kirk Lazarus',
                },{
                    name: 'Tropic Thunder',
                    src: 'Tropic_thunder_3.jpg',
                    quote: 'Okay. Flaming Dragon. Fuckface. First, take a big step back and literally fuck your own face!',
                    year: 2008,
                    author: 'Les Grossman',
                }
            ],
            selectedBackground: null,
        };
    },
    fetch() {
        this.$axios
            .$get(
                `//api.weatherapi.com/v1/current.json?key=${this.$config.weatherApiKey}&q=${this.weather.location}&aqi=no`
            )
            .then((res) => {
                this.weather.temp = res.current.temp_c;
                this.weather.icon = res.current.condition.icon;
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });

        this.$axios.$get(process.env.prodAPI + '/config/server').then((res) => {
            this.serverStatuses = res.result.statuses;
        });
    },
    computed: {
        currentTime() {
            return this.$moment().format('H:mm');
        },
        logoSource() {
            return `${process.env.backendURL}/assets/system/logo.svg`;
        },
    },
    beforeMount() {
        const random = Math.floor(Math.random() * this.backgrounds.length);
        this.selectedBackground = this.backgrounds[random];

        const T_1000 = this.$moment(new Date()).startOf('day').add(10, 'hours');
        const T_1800 = this.$moment(new Date()).startOf('day').add(18, 'hours');
        if (this.$moment(new Date()).isBefore(T_1000)) {
            this.$store.commit('setDayTime', 'morning');
        }
        if (this.$moment(new Date()).isAfter(T_1000) && this.$moment(new Date()).isBefore(T_1800)) {
            this.$store.commit('setDayTime', 'day');
        } else {
            this.$store.commit('setDayTime', 'evening');
        }
    },
};
</script>

<style lang="postcss" scoped>
.login-image:after {
    content: '';
    @apply absolute top-0 left-0 w-full h-full bg-black/70;
}
.w-26p {
    width: 26%;
}
.w-37p {
    width: 37%;
}
.logo,
.logo path,
.logo g {
    color: #fff;
}

.status-badge {
    @apply bg-dark-800 rounded py-2 px-3 text-xxs mx-2 inline-flex items-center;
    .indicator {
        @apply w-1.5 h-1.5 rounded-full ml-3;
        &.active {
            @apply bg-success;
        }
        &:not(.active) {
            @apply bg-red-500;
        }
    }
}



.min-w-100 {
    z-index: 120;
}
.min-w-100 > * {
    z-index: 120;
}
.sleigh {
    font-size: 3rem;
    color: transparent; /*set color to transparent to re-color emojis*/
    text-shadow: 0 0 0 #7d7d7d; /*recolor emojis*/
    z-index: 100;
}
.santa {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 3s, 5s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}
.santa1 {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 3.2s, 5.1s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}
.santa2 {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 3.4s, 5.2s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}
.santa3 {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 3.6s, 5.3s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}
.santa4 {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 3.8s, 5.4s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}
.santa5 {
    animation-name: up, across;
    animation-duration: 10s, 6.5s;
    animation-timing-function: ease-in-out, linear;
    animation-delay: 4s, 5.6s;
    position: absolute;
    right: -80px;
    top: 100px;
    z-index: 11;
    filter: blur(1px);
    font-size: 30px;
}

.fa-star {
    color: #0b364f;
}

.la-sleigh {
    color: #7D7D7DFF;
    transform: scale(-1, 1);
}

@keyframes up {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(100px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes across {
    from {
        right: 0%;
    }

    to {
        right: 100%;
    }
}

@keyframes idle {
    0% { visibility: visible; }
    99.9% { visibility: visible; }
    100% { visibility: hidden; } /* Láthatatlan állapot */
}

.fa-snowflake {
    color: #ecf2f8;
}

.snow {
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
}

.snowflake {
    animation-name: fall, shake;
    animation-duration: 10s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 200px;
    z-index: 11;
    filter: blur(0.5px);
}

.snowflake1 {
    animation-name: fall, shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 500px;
    top: -100px;
    animation-delay: 1s, 1s;
    z-index: 11;
    filter: blur(0.5px);
}

.snowflake2 {
    animation-name: fall, shake;
    animation-duration: 8s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 20px;
    top: -100px;
    animation-delay: 2s, 2s;
    z-index: 11;
    filter: blur(0.5px);
}

.snowflake3 {
    animation-name: fall, shake;
    animation-duration: 9s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    right: 170px;
    top: -100px;
    animation-delay: 3s, 5s;
    z-index: 11;
    filter: blur(0.7px);
}

.snowflake4 {
    animation-name: fall, shake;
    animation-duration: 15s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    right: 430px;
    top: -100px;
    animation-delay: 4s, 4s;
    z-index: 100;
}

.snowflake5 {
    animation-name: fall, shake;
    animation-duration: 15s, 4s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 500px;
    top: -100px;
    animation-delay: 6s, 2s;
    font-size: 2rem;
    z-index: 11;
    filter: blur(0.5px);
}

.snowflake6 {
    animation-name: fall, shake;
    animation-duration: 12s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 75px;
    top: -100px;
    animation-delay: 3.2s, 2s;
    font-size: 2rem;
    z-index: 11;
}

.snowflake7 {
    animation-name: fall, shake;
    animation-duration: 11s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 650px;
    top: -100px;
    animation-delay: 7.2s, 1s;
    z-index: 100;
}

.snowflake8 {
    animation-name: fall, shake;
    animation-duration: 12s, 4s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 820px;
    top: -100px;
    animation-delay: 4s, 2s;
    font-size: 2rem;
    z-index: 11;
    filter: blur(0.5px);
}

.snowflake9 {
    animation-name: fall, shake;
    animation-duration: 12s, 4s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 170px;
    top: -100px;
    animation-delay: 5s, 2s;
    font-size: 2rem;
    z-index: 11;
    filter: blur(0.3px);
}

.snowflake10 {
    animation-name: fall, shake;
    animation-duration: 15s, 9s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 350px;
    top: -100px;
    animation-delay: 2.7s, 2s;
    font-size: 3rem;
    z-index: 100;
}

.snowflake11 {
    animation-name: fall, shake;
    animation-duration: 12s, 7s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    right: 400px;
    top: -100px;
    animation-delay: 0.5s, 2s;
    font-size: 3rem;
    z-index: 11;
}

.snowflake12 {
    /*small*/
    animation-name: fall, shake;
    animation-duration: 12s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    left: 470px;
    top: -100px;
    animation-delay: 5s, 2s;
    z-index: 11;
    filter: blur(0.9px);
}

.snowflake13 {
    /*small*/
    animation-name: fall, shake;
    animation-duration: 10s, 5s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    right: 220px;
    top: -100px;
    animation-delay: 1s, 2s;
    z-index: 11;
    filter: blur(0.6px);
}

.snowflake14 {
    /*small*/
    animation-name: fall, shake;
    animation-duration: 9s, 4s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    position: absolute;
    right: 140px;
    top: -100px;
    animation-delay: 2s, 2s;
    z-index: 11;
    filter: blur(0.5px);
}

@keyframes fall {
    0% {
        top: -10%;
    }

    100% {
        top: 100%;
    }
}

@keyframes shake {
    0% {
        transform: translatex(0);
    }
    50% {
        transform: translatex(50px);
    }
    100% {
        transform: translatex(0);
    }
}

.mountains {
    width: 100%;
}

.mt1 {
    position: absolute;
    content: "";
    left: 100px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 150px solid transparent;
    border-left: 150px solid transparent;
    border-bottom: 300px solid #0b364f;
    z-index: 11;
} /*the bottom border is the only thing we see for the mts, so we give it a color. 300px is the height of the mt. All other borders should be transparent the width of the other borders will determine the angle of the triangle.*/

.mtsnow1 {
    position: absolute;
    content: "";
    left: -50px;
    bottom: -100px;
    border-top: 0px solid transparent;
    border-right: 50px solid transparent;
    border-left: 50px solid transparent;
    border-bottom: 100px solid #c1cfd7;
}

.snow-peak-1a {
    /*sharp snow edge*/
    position: absolute;
    content: "";
    bottom: -130px;
    left: -45px;
    border-top: 30px solid #c1cfd7;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-1b {
    position: absolute;
    content: "";
    bottom: -130px;
    left: -7px;
    border-top: 30px solid #c1cfd7;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.mt2 {
    position: absolute;
    content: "";
    left: -20px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 150px solid transparent;
    border-left: 150px solid transparent;
    border-bottom: 200px solid #094669;
    z-index: 11;
}

.snow2 {
    position: absolute;
    content: "";
    left: -75px;
    bottom: -98px;
    border-top: 0px solid transparent;
    border-right: 75px solid transparent;
    border-left: 75px solid transparent;
    border-bottom: 100px solid #e4e8eb;
}

.snow-peak-2a {
    position: absolute;
    content: "";
    bottom: -110px;
    left: -60px;
    border-top: 20px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-2b {
    position: absolute;
    content: "";
    bottom: -120px;
    left: -25px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-2c {
    position: absolute;
    content: "";
    bottom: -110px;
    right: -60px;
    border-top: 20px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.mt3 {
    position: absolute;
    content: "";
    left: 280px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 150px solid transparent;
    border-left: 150px solid transparent;
    border-bottom: 175px solid #094669;
    z-index: 11;
}

.snow3 {
    position: absolute;
    content: "";
    left: -70px;
    bottom: -80px;
    border-top: 0px solid transparent;
    border-right: 70px solid transparent;
    border-left: 70px solid transparent;
    border-bottom: 90px solid #e4e8eb;
}

.snow-peak-3a {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -60px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-3b {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -20px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-3c {
    position: absolute;
    content: "";
    bottom: -100px;
    right: -20px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-3d {
    position: absolute;
    content: "";
    bottom: -100px;
    right: -60px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.mt4 {
    position: absolute;
    content: "";
    left: 800px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 150px solid transparent;
    border-left: 150px solid transparent;
    border-bottom: 250px solid #0b364f;
    z-index: 11;
}

.snow4 {
    position: absolute;
    content: "";
    left: -47px;
    bottom: -75px;
    border-top: 0px solid transparent;
    border-right: 47px solid transparent;
    border-left: 47px solid transparent;
    border-bottom: 80px solid #c1cfd7;
}

.snow-peak-4a {
    /*sharp snow edge*/
    position: absolute;
    content: "";
    bottom: -100px;
    left: -45px;
    border-top: 30px solid #c1cfd7;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
} /* the sharp snow edge an is INVERTED triangle, so we want to see the the top border,not bottom. Top border should have a color. Other borders should be transparent*/

.snow-peak-4b {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -7px;
    border-top: 30px solid #c1cfd7;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.mt5 {
    position: absolute;
    content: "";
    left: 500px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 200px solid transparent;
    border-left: 200px solid transparent;
    border-bottom: 150px solid #094669;
    z-index: 11;
}

.snow5 {
    position: absolute;
    content: "";
    left: -100px;
    bottom: -75px;
    border-top: 0px solid transparent;
    border-right: 100px solid transparent;
    border-left: 100px solid transparent;
    border-bottom: 75px solid #e4e8eb;
}

.snow-peak-5a {
    position: absolute;
    content: "";
    bottom: -95px;
    left: -60px;
    border-top: 20px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-5b {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -25px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-5c {
    position: absolute;
    content: "";
    bottom: -95px;
    right: -60px;
    border-top: 20px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.mt6 {
    position: absolute;
    content: "";
    left: 950px;
    bottom: 0;
    border-top: 0px solid transparent;
    border-right: 150px solid transparent;
    border-left: 150px solid transparent;
    border-bottom: 175px solid #094669;
    z-index: 11;
}

.snow6 {
    position: absolute;
    content: "";
    left: -70px;
    bottom: -80px;
    border-top: 0px solid transparent;
    border-right: 70px solid transparent;
    border-left: 70px solid transparent;
    border-bottom: 90px solid #e4e8eb;
}

.snow-peak-6a {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -60px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-6b {
    position: absolute;
    content: "";
    bottom: -100px;
    left: -20px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-6c {
    position: absolute;
    content: "";
    bottom: -100px;
    right: -20px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}

.snow-peak-6d {
    position: absolute;
    content: "";
    bottom: -100px;
    right: -60px;
    border-top: 30px solid #e4e8eb;
    border-right: 25px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 25px solid transparent;
}


</style>
