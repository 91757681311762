
<template>
        <div v-if="row.entityId !== 0" class="flex items-center">
            <p v-if="row.type === 1">General</p>
            <p v-else-if="row.type === 2 && row.subject !== null">
                <project-badge v-if="$checkPermission('project.read')" :project="row.subject" link />
                <span v-else>{{ row.subject ? row.subject.name : '-' }}</span>
            </p>
            <p v-else-if="row.type === 3 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission(['project.read', 'bid.read_list'])"
                    :to="`/projects/${row.project.id}/details/bids`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 4 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission('project.read') && $checkPermission('resource.read_list')"
                    :to="`/projects/${row.project.id}/work/resources`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 5 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission(['project.read', 'shot.read_list'])"
                    :to="`/projects/${row.project.id}/work/shots/${row.entityId}`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 6 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission('project.read') && $checkPermission('daily.read_list')"
                    :to="`/projects/${row.project.id}/work/dailies`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 7 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission(['project.read', 'broadcast.read_list'])"
                    :to="`/projects/${row.project.id}/work/broadcast`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 8 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission('project.read') && $checkPermission('submit.read')"
                    :to="`/projects/${row.project.id}/work/shots/${row.subject.shot.id}/${row.subject.passType.toLowerCase()}/${row.subject.id}`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type === 9 && row.project !== null && row.project.id !== null">
                <nuxt-link
                    v-if="$checkPermission('task.read')"
                    :to="`/task/${row.subject.id}`"
                    target="_blank"
                    class="text-xs inline-flex items-center group transition-colors text-secondary dark:text-white hover:text-primary"
                >
                    <project-badge icon-only :project="row.project" />
                    {{ formatLink(row.project.name + '/' + row.prettyLink) || '' }}
                </nuxt-link>
                <span v-else> {{ formatLink(row.prettyLink) || '' }}</span>
            </p>
            <p v-else-if="row.type !== 1 && row.subject !== null && row.subject.id !== null">
                {{ row.subject ? row.subject.name : '-' }}
            </p>
        </div>
        <span v-else>-</span>
</template>

<script>

export default {
    props: {
        row: { type: Object, required: true},
    },

    methods: {
        formatLink(link = '') {
            if (this.$store.state.users.me.screenSize >= 3) return link;
            const slashIter = link.matchAll(/\//gm);
            const slashes = [];
            while(true) {
                const next = slashIter.next();
                if (!next.done) {
                    slashes.push(next.value.index);
                } else {
                    break;
                }
            }
            if (slashes.length >= 2) {
                return link.slice(slashes[slashes.length-2]);
            }
            return link;
        }
    }
}
</script>