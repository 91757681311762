import Vue from 'vue';

Vue.mixin({
    computed: {
        ww() {
            return this.$store.state.globals.windowWidth;
        },
        $user() {
            return this.$store.state.users.me.me;
        },
    },
    methods: {
        getAvatarImage(src) {
            if (!src) {
                return require('~/assets/img/base/default-avatar.png');
            }

            return `${process.env.backendURL}/images/avatars/${src}`;
        },
    },
});
