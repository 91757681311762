<template>
    <transition name="fade" mode="out-in">
        <div
            v-if="(submit && (submit.afJob || (!submit.afJob && submit.internalMovFile))) || (daily && daily.afJob)"
            class="plyr-font-mono"
            :class="`baseVideoPlayer-${_uid} ${(isVertical ? 'vertical' : '')}`"
        >
            <vue-plyr
                v-if="progressState === 'DON' || (!submit.afJob && submit.internalMovFile)"
                ref="videoPlayer"
                :options="options"
            >
                <video controls crossorigin playsinline>
                    <source :src="src" type="video/mp4" />
                </video>
            </vue-plyr>
            <div v-else class="flex items-center justify-center h-50">
                <i class="animate-spin las la-circle-notch mr-2" />
                Waiting for farm...
            </div>
        </div>
        <div v-else />
    </transition>
</template>

<script>
export default {
    name: 'BaseVideoPlayer',
    props: {
        submit: { type: Object, required: false, default: null },
        daily: { type: Object, required: false, default: null },
        small: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            frameLabel: null,
            options: {
                loop: { active: true },
                controls: !this.small ? [
                    'rewind', // Rewind by the seek time (default 10 seconds)
                    'play', // Play/pause playback
                    'fast-forward', // Fast-forward by the seek time (default 10 seconds)
                    'progress', // The progress bar and scrubber for playback and buffering
                    'current-time', // The current time of playback
                    'duration', // The full duration of the media
                    'mute', // Toggle mute
                    'volume', // Volume control
                    'settings', // Settings menu
                    'fullscreen', // Toggle fullscreen
                ] : ['play', 'progress', 'mute', 'volume', 'fullscreen'],
                seekTime: 1.0 / (
                    this?.submit?.shot?.outputSettings?.frameRate ? this.submit.shot.outputSettings.frameRate :
                        this?.daily?.submit?.shot?.outputSettings?.frameRate ? this.daily.submit.shot.outputSettings.frameRate :
                            24.0
                ),
                volume: 0.5,
                hideControls: false,
                speed: {
                    selected: 1,
                    options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4, 8]
                },
            },
        };
    },
    computed: {
        progressState() {
            if (this.submit) return this.submit.afJob?.progressState;
            return this.daily?.submit?.afJob?.progressState;
        },
        refSubmit() {
            if (this.submit) return this.submit;
            return this.daily?.submit;
        },
        src() {
            // return 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
            if (this.submit) {
                return `${process.env.prodAPI}/submits/${this.submit.id}/media?token=${this.$store.state.auth.token}&t=${new Date().getTime()}`;
            } else if (this.daily) {
                return `${process.env.prodAPI}/dailies/${this.daily.id}/download-mov?token=${this.$store.state.auth.token}&t=${new Date().getTime()}&view=1`;
            }
            return '';
        },
        isVertical() {
            let refSubmit = null;
            if (this.submit) { refSubmit = this.submit; }
            else if (this.daily) { refSubmit = this.daily.submit; }
            if (!refSubmit) return false;
            const sides = refSubmit.shot.outputSettings.aspectRatio.split(':');
            return +sides[0] < +sides[1];
        },
    },
    mounted() {
        const timeLabel = document.querySelector(".plyr__controls__item.plyr__time--current.plyr__time");
        if (timeLabel) {
            this.frameLabel = document.createElement("div");
            this.frameLabel.classList.add("ml-2");
            this.frameLabel.classList.add("plyr__time");
            timeLabel.parentNode.insertBefore(this.frameLabel, timeLabel);

            setTimeout(() => {
                this.$refs.videoPlayer.player.media.ontimeupdate = this.updateFrame;
                this.$refs.videoPlayer.player.config.speed.selected = 1;
                this.$refs.videoPlayer.player.media.playbackRate = 1;

                this.updateFrame();
            }, 1000);
        }
    },
    methods: {
        updateFrame() {
            let firstFrame = parseInt(this.refSubmit?.originalFileName?.split('.')?.slice(-2,-1)[0]);
            if (!firstFrame) firstFrame = 1;
            const frame = Math.round(this.$refs.videoPlayer.player.media.currentTime / this.options.seekTime) + firstFrame;
            const frameScale = Math.ceil(Math.log10(this.refSubmit.frameNumber + firstFrame));
            this.frameLabel.innerHTML = '#' + String(frame).padStart(frameScale, '0');
        }
    },
};
</script>

<style>
[class*="baseVideoPlayer-"] {
    .plyr {
        flex-direction: column-reverse;

        * {
            font-family: monospace;
            font-weight: 700;
        }
    }
    .plyr__controls {
        position: unset !important;
        padding-top: 0.5em !important;
        width: 100%;
    }
}
</style>
