import Vue from 'vue'
import VueLodash from 'vue-lodash'
// import lodash from 'lodash'
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

Vue.use(VueLodash, { name: '$_', lodash: { cloneDeep, debounce, some, isEmpty, orderBy } })
