<template>
    <v-menu
        ref="headerDownloadsMenu"
        offset-y
        open-on-focus
        :close-delay="hangTime"
        :nudge-left="157"
        persistent
    >
        <template #activator="{ on }">
            <div>
                <v-btn
                    icon
                    medium
                    class="ml-1"
                    :class="{'hidden': !recentActivity}"
                    title="Downloads"
                    v-on="on"
                    @mouseenter="openMenu"
                    @mouseleave="closeMenu"
                    @click="iconClick"
                >
                    <i class="las la-download" :class="{'animate-pulse-more': dlInProgress}" />
                    <span class="absolute" :class="doPing ? 'inline-flex' : 'hidden'" style="top: 0.15rem; left: 0.5rem;">
                        <i class="las la-download" :class="{'animate-ping': doPing}" />
                    </span>
                    <span
                        v-if="newDl"
                        class="absolute top-0 right-0 border-3 border-gray-300 dark:border-dark-800 bg-red text-white rounded-full w-4 h-4 flex items-center justify-center"
                    />
                </v-btn>
            </div>
        </template>

        <div
            class="v-menu-body z-25"
            @mouseenter="openMenu"
            @mouseleave="closeMenu"
        >
            <div v-for="dl of downloads.filter(x => !x.cleared)" :key="dl.id" class="dl-item">
                <div class="flex w-max text-xs text-gray-500">
                    {{dl.title}}
                </div>
                <div v-if="dl.afJob && !dl.afJob.progressState" class="flex items-center w-max text-base text-left my-3 ml-2">
                    <i class="animate-spin las la-circle-notch" />
                    <span class="ml-2">Zipping files...</span>
                </div>
                <div v-else-if="dl.afJob && dl.afJob.progressState === 'DON'" class="w-max text-base text-left my-3 ml-2">
                    <a @click="() => download(dl.id)">
                        <v-btn v-if="!dl.downloaded" color="secondary" :disabled="!!dl.downloaded">
                            <span>Download</span>
                        </v-btn>
                        <span v-else>Downloaded</span>
                    </a>
                </div>
                <div v-else-if="dl.afJob && dl.afJob.progressState === 'ERR'" class="w-max text-base text-left my-3 ml-2">
                    Error while preparing download<br>Please try again
                </div>
                <div class="flex w-max text-xs text-gray-500">
                    <date :date="dl.created_meta.date" class="ml-auto" />
                </div>

            </div>
            <div class="flex gap-4 mt-2">
                <v-btn color="tertiary" to="/downloads"><span class="px-1">Show all</span></v-btn>
                <v-btn color="tertiary" class="ml-auto" @click="clearDownloads"><span class="px-4">Clear</span></v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import uniqId from 'uniqid';

export default {
    data() {
        return {
            tabId: uniqId(),
            justOpened: false,
            hangTime: 200,
            shouldClose: false,
            downloads: [],
            newDl: false,
            doPing: false,
        }
    },
    async fetch() {
        await this.$axios
            .$get(`${process.env.prodAPI}/downloads?order=-createdAt&scopes[]=with_active&limit=5`)
            .then((res) => {
                this.downloads = [...res.extra.active, ...res.result].slice(0,5);
            })
            .catch(e => {
                for (const error of e.response.data.errors) {
                    this.$notify({
                        title: e.response.data.message,
                        text: `${error.key}: ${error.message}`,
                        type: 'error'
                    });
                }
            });
    },
    computed: {
        ...mapGetters({
            me: 'users/me/getMe',
        }),
        dlInProgress() {
            return !!this.downloads.find(x => x.afJob && !x.afJob.progressState && !x.cleared);
        },
        recentActivity() {
            return !!this.downloads.find(x => this.$moment(x.created_meta.date).add(8, 'hours').isAfter(new Date()) && !x.cleared);
        },
    },
    mounted() {
        this.$nuxt.$on('fetchDownloads', payload => this.handleFetchDownloads(payload));
        this.$nuxt.$on('newDownload', (type, id, openTab) => this.newDownload(type, id, openTab));
        this.$nuxt.$on('startDownload', id => this.download(id));
    },
    destroyed() {
        this.$nuxt.$off('fetchDownloads');
        this.$nuxt.$off('newDownload');
        this.$nuxt.$off('startDownload');
    },
    methods: {
        async handleFetchDownloads(payload) {
            if (payload) {
                if (payload.download?.tabId !== this.tabId) {
                    await new Promise(resolve => setTimeout(resolve, 5000));
                }
                await this.$fetch();
                if (this.downloads.find(x => x.id === payload.download?.id)) {
                    if (this.tabId === payload.download?.tabId) {
                        this.download(payload.download?.id);
                    } else {
                        this.newDl = true;
                    }
                }
            }
        },
        newDownload(type, id, openTab = false) {
            const postData = new FormData();
            postData.append('type', type);
            postData.append('entityId', id);
            postData.append('tabId', this.tabId);

            this.$axios.$post(`${process.env.prodAPI}/downloads`, postData)
                .then((res) => {
                    this.downloads = [res.result, ...this.downloads];
                    if (this.downloads.length > 5) {
                        this.downloads = this.downloads.slice(0,5);
                    }
                    this.$notify({ title: res.message, type: 'info' });
                    if (type === 'resource' && res.result.downloaded) {
                        this.$downloadFile(`${process.env.prodAPI}/resources/${id}/download`);
                    } else {
                        this.$store.commit('users/me/addUnloadBlocker', `dl_${res.result.id}`);
                    }
                    this.headerPing();
                    if (openTab) {
                        const fakeLink = document.createElement('a');
                        fakeLink.href = `${location.origin}/downloads`;
                        fakeLink.setAttribute('target', '_blank');
                        document.body.appendChild(fakeLink);
                        fakeLink.click();
                        fakeLink.remove();
                    } else {
                        this.$refs.headerDownloadsMenu._data.isActive = true;
                        setTimeout(() => { this.$refs.headerDownloadsMenu._data.isActive = false; }, 3000);
                    }
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        download(id) {
            const dl = this.downloads.find(x => x.id === id);
            if (!dl || !dl.downloaded) {
                const dlLink = `${process.env.prodAPI}/downloads/${id}?token=${this.$store.state.auth.token}`;
                const dlWindow = window.open(dlLink,"VFXPussy Download");
                try {
                    dlWindow.focus();
                    if (dl) dl.downloaded = true;
                    this.headerPing();
                    setTimeout(() => {
                        this.$fetch();
                        this.$nuxt.$emit('fetchDownloads');
                    }, 3000);
                } catch (e) {
                    this.$notify({
                        title: "Download couldn't start",
                        text: "Please click the manual Download button in the top right menu bar",
                        type: 'info'
                    });
                }
                this.$store.commit('users/me/removeUnloadBlocker', `dl_${id}`);
            }
        },
        clearDownloads() {
            this.$axios
                .$post(`${process.env.prodAPI}/downloads/clear`)
                .then(() => {
                    this.downloads.forEach(x => { x.cleared = 1; });
                })
                .catch(e => {
                    for (const error of e.response.data.errors) {
                        this.$notify({
                            title: e.response.data.message,
                            text: `${error.key}: ${error.message}`,
                            type: 'error'
                        });
                    }
                });
        },
        iconClick() {
            if (!this.hovered) {
                setTimeout(() => {
                    this.$refs.headerDownloadsMenu._data.isActive = true;
                }, 50);
            } else {
                this.$router.push('/downloads');
            }
        },
        openMenu() {
            this.$refs.headerDownloadsMenu._data.isActive = true;
            this.shouldClose = false;
            this.hovered = true;
        },
        closeMenu() {
            this.shouldClose = true;
            setTimeout(() => {
                if (this.shouldClose) {
                    this.$refs.headerDownloadsMenu._data.isActive = false;
                }
            }, this.hangTime);
            this.hovered = false;
        },
        headerPing() {
            this.doPing = true;
            setTimeout(() => { this.doPing = false; }, 1000);
        }
    }
};
</script>

<style>
.dl-item {
    @apply flex flex-wrap items-center justify-start w-80 py-2 text-white border-b border-gray-600;
}

.animate-pulse-more {
    animation: pulse-more 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse-more {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: .3;
        }
    }
}
</style>
