<template>
    <header class="dialog-header">
        <div class="flex items-center w-max">
            <h3 class="font-gotham-700">
                <i v-if="icon" :class="`las ${icon} mr-2`" />
                {{ text }}
            </h3>
            <slot />
        </div>
        <div class="flex ml-auto">
            <v-btn v-if="!noTab" icon small :to="$route.fullPath" title="Open in new tab" target="_blank"><i class="las la-external-link-alt" /></v-btn>
            <span v-if="!noTab" class="ml-2 mt-2 -mr-2">|</span>
            <v-btn icon small title="Close" @click="$emit('closeModal')">
                <i class="las la-times" />
            </v-btn>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        text: { type: String, required: false, default: '' },
        icon: { type: String, required: false, default: '' },
        noTab: { type: Boolean, required: false, default: false },
    }
}
</script>