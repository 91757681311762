<template>
    <div>
        <!-- Default: Autoformat, Block quote, Bold, Link, Image, Heading, Image caption, Image style, Image toolbar, Image upload, Indent, Italic, List, Media embed, Paste from Office, Table, Table toolbar, Text transformation -->
        <!-- Added: Underline, Base64 upload adapter, Font background color, Font color, Remove format, Alignment, Autolink, Image resize -->
        <ckeditor
            :editor="editorFull"
            :value="value"
            :config="config"
            @input="(event) => $emit('input', event)"
            @ready="onEditorReady"
        />
        <transition name="fade">
            <div v-if="errorMessages && errorMessages.length > 0">
                <p class="text-xs px-3 mt-1 text-red/50">{{ errorMessages[0] }}</p>
            </div>
        </transition>
    </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CustomBuild from '~/assets/ckeditor5';

export default {
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        setFocus: { type: Boolean, required: false, default: false },
        slim: { type: Boolean, required: false, default: false },
        value: { type: String, required: false, default: '' },
        errorMessages: { type: Array, required: false, default: null },
    },
    data() {
        return {
            editorFull: CustomBuild,
            config: this.slim ? { toolbar: ['Bold', 'Italic', 'Underline', 'FontColor', 'Emoji'],  mediaEmbed: {
                    previewsInData: true
                } } : { mediaEmbed: {
                    previewsInData: true
                }} ,
        };
    },
    methods: {
        onEditorReady(editor) {
            if (this.setFocus === true) {
                editor.model.change((writer) => {
                    writer.setSelection(editor.model.document.getRoot(), 'end');
                });
                editor.editing.view.focus();
            }
        },
    },
};
</script>
