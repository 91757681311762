var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vc-date-picker',{attrs:{"is24hr":"","model-config":{ type: 'date', mask: 'iso' },"is-dark":_vm.$colorMode.value === 'dark',"mode":_vm.mode,"masks":{
        inputDateTime24hr: 'YYYY. MMM. D. HH:mm',
        input: 'YYYY. MMM D.',
    },"valid-hours":_vm.mode === 'dateTime' ? { min: 0, max: 23 } : { min: 0, max: 8 },"minute-increment":15,"disabled-dates":_vm.disabledDates,"popover":{ positionFixed: true, visibility: 'hover' }},on:{"input":function($event){return _vm.watchDate($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var inputValue = ref.inputValue;
    var togglePopover = ref.togglePopover;
return [_c('div',{staticClass:"relative"},[_c('v-text-field',{attrs:{"value":inputValue,"placeholder":_vm.placeholder,"prepend-inner-icon":"las la-calendar","solo":"","dense":"","hide-details":"","readonly":"","disabled":_vm.disabled},on:{"click":function($event){return togglePopover({ isInteractive: true })}}}),_vm._v(" "),(_vm.clearable && !_vm.disabled)?_c('transition',{attrs:{"name":"fade"}},[(inputValue !== '')?_c('button',{staticClass:"absolute right-0 top-0 h-10 w-10 rounded inline-flex justify-center items-center dark:bg-dark-800",attrs:{"type":"button"},on:{"click":function($event){_vm.$emit('updateDate', '');
                        _vm.clearValue();}}},[_c('i',{staticClass:"las la-times text-red bg-gray-200 dark:bg-dark-800"})]):_vm._e()]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showError)?_c('div',[_c('p',{staticClass:"text-xs px-3 mt-1 text-red/50"},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])],1)]}}]),model:{value:(_vm.datePickerModel),callback:function ($$v) {_vm.datePickerModel=$$v},expression:"datePickerModel"}})}
var staticRenderFns = []

export { render, staticRenderFns }