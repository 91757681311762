export const state = () => ({
  lastMessageTab: null,
  lastNotesTab: null,
  lastDispoTab: null,
  savedPreproductionProjectTabRoutes: {},
  savedProjectTabRoutes: {},
  savedProjectShotRoutes: {},
  savedProjectDetailRoutes: {},
  savedShotVersionRoutes: {},
  savedSequencePanelOpenStates: {},
  savedProjectFolderPanelOpenStates: {},
  savedShotDetailsPanelOpenStates: {},
  savedProjectResourcesFilterOpenStates: {},
  savedProjectShotAccordionOpenStates: {},
})

export const mutations = {
  setLastMessageTab(state, payload) {
    state.lastMessageTab = payload
  },
  setLastNotesTab(state, payload) {
    state.lastNotesTab = payload
  },
  setLastDispoTab(state, payload) {
    state.lastDispoTab = payload
  },
  setSavedPreproductionProjectTabRoute(state, payload) {
    state.savedPreproductionProjectTabRoutes[payload.projectID] = payload.tabRoute
  },
  setSavedProjectTabRoute(state, payload) {
    state.savedProjectTabRoutes[payload.projectID] = payload.tabRoute
  },
  setSavedProjectShotRoute(state, payload) {
    state.savedProjectShotRoutes[payload.projectID] = payload.shotRoute
  },
  setSavedProjectDetailRoute(state, payload) {
    state.savedProjectDetailRoutes[payload.projectID] = payload.detailRoute
  },
  setSavedShotVersionRoute(state, payload) {
    state.savedShotVersionRoutes[payload.shotID] = payload.versionRoute
  },
  setSavedSequencePanelOpenState(state, payload) {
    state.savedSequencePanelOpenStates[payload.key] = payload.value
  },
  setSavedProjectFolderPanelOpenStates(state, payload) {
    state.savedProjectFolderPanelOpenStates[payload.key] = payload.value
  },
  setSavedShotDetailsPanelOpenStates(state, payload) {
    state.savedShotDetailsPanelOpenStates[payload.key] = payload.value
  },
  setSavedProjectResourcesFilterOpenStates(state, payload) {
    state.savedProjectResourcesFilterOpenStates[payload.key] = payload.value
  },
  setSavedProjectShotAccordionOpenStates(state, payload) {
    state.savedProjectShotAccordionOpenStates[payload.key] = payload.value
  },
}
