<template>
  <div>
    <response-notification />
    <wss-notification />
  </div>
</template>

<script>
import ResponseNotification from '~/components/UI/Notifications/ResponseNotification.vue'
import WssNotification from '~/components/UI/Notifications/WssNotification.vue'
export default {
  components: {
    ResponseNotification,
    WssNotification
  }
}
</script>
