<template>
    <div class="text-center flex pt-64 items-center justify-center flex-col">
        <h1 class="text-9xl font-bold mb-2">{{ error.statusCode }}</h1>
        <p class="mb-5">{{ error.message }}</p>
        <div class="flex items-center justify-center">
            <v-btn color="primary" @click="refresh">Refresh</v-btn>
            <span class="mx-4 text-xs">or</span>
            <v-btn @click="$router.go(-1)">Back</v-btn>
            <!-- <span class="mx-2 text-xs">or</span>
      <v-btn to="/">Back to home</v-btn> -->
        </div>
    </div>
</template>

<script>
export default {
    layout: 'ErrorLayout',
    props: {
        error: { type: Object, required: true, default: null },
    },
    methods: {
        refresh() {
            this.$router.go({ path: this.$route.fullPath, force: true });
        },
    },
};
</script>
