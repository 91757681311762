export const state = () => ({
    resourcesUnderUpload: [],
    submitsUnderUpload: [],
});

export const mutations = {
    // resources
    addResourceUnderUpload(state, payload) {
        state.resourcesUnderUpload.push(payload);
    },
    updateResourceUnderUpload(state, payload) {
        state.resourcesUnderUpload.find((r) => r.uppyID === payload.uppyID).progress = payload.progress;
    },
    removeResourceUnderUpload(state, payload) {
        state.resourcesUnderUpload = state.resourcesUnderUpload.filter((r) => r.uploadID !== payload.uploadID);
    },
    // submits
    addSubmitUnderUpload(state, payload) {
        state.submitsUnderUpload.push(payload);
    },
    updateSubmitUnderUpload(state, payload) {
        state.submitsUnderUpload.find((r) => r.uppyID === payload.uppyID).progress = payload.progress;
        state.submitsUnderUpload.find((r) => r.uppyID === payload.uppyID).pings = payload.pings;
    },
    removeSubmitUnderUpload(state, payload) {
        state.submitsUnderUpload = state.submitsUnderUpload.filter((r) => r.uploadID !== payload.uploadID);
    },
};
