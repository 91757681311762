<template>
    <div class="text-center" :class="{ 'absolute top-0 left-0 w-full h-full pt-10': table }">
        <div :class="card ? 'card' : 'bg-white dark:bg-dark-800 p-6'">
            <div v-if="customErrorMessage">
                <p><i class="las la-exclamation-triangle text-yellow text-6xl mb-2" /></p>
                <p>{{ customErrorMessage }}</p>
            </div>
            <div v-else>
                <p><i class="las la-exclamation-triangle text-red text-6xl mb-2" /></p>
                <p class="text-xs">{{ error.statusCode || error.status }}</p>
                <p>{{ error.message || error.data.message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: { type: Object, required: false, default: null },
        table: { type: Boolean, required: false, default: false },
        card: { type: Boolean, required: false, default: false },
        customErrorMessage: { type: String, required: false, default: '' },
    },
};
</script>

<style></style>
