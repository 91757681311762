<template>
    <div class="border-t border-gray-300 dark:border-dark-800 mx-10 py-6">
        <ul>
            <li v-for="result in results" :key="result.id">
                <button
                    class="h-14 flex w-full items-center py-3 px-3 bg-white dark:bg-dark-850 rounded-md transition-colors hover:bg-gray-300 dark-hover:bg-dark-800 focus:bg-gray-300 dark-focus:bg-dark-800 focus:outline-none"
                    @click="goToResult(result)"
                >
                    <i class="las la-clipboard-list w-8 h-8 inline-flex items-center justify-center mr-2 text-2xl" />
                    <span class="inline-block mr-2 font-gotham-700">{{ result.content.name }}</span>
                    <small class="text-gray-500"> - {{ result.type }}</small>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        results: { type: Array, required: true, default: null },
    },
    methods: {
        goToResult(result) {
            if (result.type === 'project') {
                if (result.entityId !== +this.$route.params.id) {
                    this.$router.push(`/projects/${result.entityId}`);
                }
            } else if (result.type === 'shot') {
                this.$router.push(`/projects/${result.extraContent.project}/work/shots/${result.extraContent.id}`);
            } else if (result.type === 'brand') {
                this.$router.push('/system?tab=brands');
            } else if (result.type === 'client') {
                this.$router.push(`/manage/clients/${result.entityId}`);
            }
            this.$emit('closeSearch');
        },
    },
};
</script>
