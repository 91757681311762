<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="1128"
    >
        <template #activator="{ on }">
            <v-btn
                v-if="!useAnchor"
                :icon="buttonIsIcon"
                :color="buttonColor"
                :left="buttonLeft"
                :block="buttonBlock"
                :text="buttonIsText"
                :fab="buttonIsFab"
                :small="buttonIsSmall"
                :class="buttonClasses"
                title="Show message"
                v-on="on"
                @click="markRead"
            >
                <slot><i class="las la-trash" /></slot>
            </v-btn>
            <a
                v-else
                v-on="on"
                @click="markRead"
            >
                <slot><i class="las la-trashcan" /></slot>
            </a>
        </template>

        <div class="dialog-body">
            <modal-header no-tab text="" @closeModal="closeModal">
                <div class="mr-2 text-lg whitespace-nowrap">Message from:</div>
                <user-badge
                    :id="$checkPermission('user.read') ? message.sender.id : null"
                    :user="message.sender"
                    :arrows="false"
                    full-name
                    disable-tooltip
                />
                <div class="ml-5 mr-2 text-lg whitespace-nowrap">On:</div>
                <date
                    :date="message.created_meta.date"
                    prevent-text-ellipsis
                    prepend-full-date
                    hide-tooltip
                    class="whitespace-nowrap"
                />
                <div v-if="message.project" class="ml-5 mr-2 text-lg whitespace-nowrap">Project:</div>
                <a v-if="message.project" style="cursor:pointer;" @click="closeModalWithProjectRedirect" >
                    <project-badge v-if="message.project" :project="message.project" />
                </a>
            </modal-header>
            <p>
                <span v-html="getLinkifiedHTML(message.payload)"> </span>
            </p>
            <footer class="dialog-footer">
                <div class="col-span-3">
                    <message-link :row="message" />
                </div>
                <div>
                    <v-btn v-if="$checkPermission('message.create')" block color="primary" @click="sendMessage()">
                        <i class="las la-envelope mr-2" />Send Message
                    </v-btn>
                </div>
                <div>
                    <v-btn block @click="closeModal">Close</v-btn>
                </div>
            </footer>
        </div>
    </v-dialog>
</template>

<script>
import linkifyHtml from 'linkifyjs/html';
import { mapGetters } from 'vuex';
import MessageLink from '@/components/Messages/MessageLink.vue';

export default {
    components: { MessageLink },
    props: {
        useAnchor: { type: Boolean, required: false, default: false },
        buttonIsText: { type: Boolean, required: false, default: false },
        buttonBlock: { type: Boolean, required: false, default: false },
        buttonIsIcon: { type: String, required: false, default: '' },
        buttonIsFab: { type: Boolean, required: false, default: false },
        buttonIsSmall: { type: Boolean, required: false, default: false },
        buttonColor: { type: String, required: false, default: '' },
        buttonLeft: { type: Boolean, required: false, default: false },
        buttonClasses: { type: String, required: false, default: '' },
        disabled: { type: Boolean, required: false, default: false },
        modalText: { type: String, required: false, default: '' },
        buttonText: { type: String, required: false, default: '' },
        message: { type: Object, required: true },
        type: { type: String, required: true },
    },
    data() {
        return {
            dialog: false,
        };
    },
    computed: {
        ...mapGetters({
            me: 'users/me/getMe',
        }),
    },
    methods: {
        closeModalWithProjectRedirect() {
            this.$router.push(`/projects/${this.message.project.id}`);
            this.closeModal();
        },
        closeModal() {
            setTimeout(() => { this.dialog = false; }, 50);
        },
        sendMessage() {
            this.$store.dispatch('messages/setShowNewMessage', { state: true, entityId: null, type: null, recipient: this.message.sender.id });
        },
        getLinkifiedHTML(str) {
            if (!str) {
                return;
            }

            return linkifyHtml(str, {
                target: '_blank',
            });
        },
        isSeen(row) {
            let seen = false;
            row.recipients.forEach(recipient => {
                if (recipient.user.id === this.me.id) {
                    seen = !!recipient.seen;
                }
            })
            return seen;
        },
        markRead() {
            const seen = this.isSeen(this.message);

            if (!seen && (this.type === 'inbox' || this.useAnchor)) {
                const postData = new FormData();
                postData.append('seen', !seen);
                this.$axios
                    .$post(process.env.prodAPI + '/messages/inbox/' + this.message.id, postData)
                    .then(() => {
                        this.$store.commit('messages/setRefreshMessagesTable', true);
                        this.$store.dispatch('messages/fetchHeaderMessages');
                    })
                    .catch((e) => {
                        for (const error of e.response.data.errors) {
                            this.$notify({
                                title: e.response.data.message,
                                text: `${error.key}: ${error.message}`,
                                type: 'error',
                            });
                        }
                    });
            }
        },
    },
};
</script>

<style>
.v-menu-body .v-btn {
    justify-content: flex-start;
    @apply px-2 !important;
}
.message-button-width-100 {
    width: 100% !important;
    white-space: nowrap !important;
}
.message-view-button-identifier>span {
    width: 100% !important;
}
.message-view-button-identifier {
    height:unset !important;
    max-height: 2rem !important;
}
</style>
