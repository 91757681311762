<template>
    <div :class="!disableTooltip ? `open-user-monogram_${id}` : ''">
        <div
            class="flex items-center"
            :class="{
                'text-xs group transition-colors text-secondary dark:text-white hover:text-primary': !!link,
                'opacity-50': user.state === 0,
            }"
        >
            <span class="bg-image relative mr-2" :class="isSmall ? 'w-5 min-w-5 h-5' : 'w-8 min-w-8 h-8'">
                <span v-if="user.isLoggedIn" class="online-badge"></span>
                <img class="rounded-full" :src="getAvatarImage(user.avatar)" alt="" />
            </span>
            <p
                v-if="!icon"
                class="h-full inline-flex items-center badge-label"
                :class="color === 'white' ? 'text-white ' : ''"
            >
                <span v-if="system" class="text-xs whitespace-nowrap">
                    System
                </span>
                <span v-else-if="user.id === me.id && (shortName || monograms) && !fullName" class="text-xs whitespace-nowrap">
                    Me {{ jobTitle ? ' - ' + jobTitle : '' }}
                </span>
                <span v-else-if="monograms && firstName && lastName" class="text-xs whitespace-nowrap">
                    {{ firstName.charAt(0) }}{{ lastName.charAt(0) }}{{ jobTitle ? ' - ' + jobTitle : '' }}
                </span>
                <span v-else-if="fullName" class="text-xs whitespace-nowrap">
                    {{ firstName }} {{ lastName }}{{ jobTitle ? ' - ' + jobTitle : '' }}
                </span>
                <span v-else-if="shortName && firstName && lastName" class="text-xs whitespace-nowrap">
                    {{ firstName }} {{ lastName.charAt(0) }}.{{ jobTitle ? ' - ' + jobTitle : '' }}
                </span>
                <span v-else-if="!firstName && !lastName" class="text-xs whitespace-nowrap">
                    Deleted User {{ jobTitle ? ' - ' + jobTitle : '' }}
                </span>
                <span v-if="user.state === 0 && screenSize >= 2" class="ml-2">(Inactive)</span>
            </p>
        </div>
        <v-tooltip top :activator="`.open-user-monogram_${id}`" z-index="210">
            <span v-if="system" class="text-xs">
                    Created By <b>System</b>
            </span>
            <span v-else-if="!firstName && !lastName" class="text-xs">
                    Deleted User {{ jobTitle ? ' - ' + jobTitle : '' }}
            </span>
            <span v-else class="text-xs">{{ firstName }} {{ lastName }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        user: { type: Object, required: true },
        monograms: { type: Boolean, required: false, default: false },
        shortName: { type: Boolean, required: false },
        fullName: { type: Boolean, required: false },
        link: { type: Boolean, required: false, default: false },
        arrows: { type: Boolean, required: false, default: true },
        color: { type: String, required: false, default: '' },
        isSmall: { type: Boolean, required: false },
        icon: { type: Boolean, required: false, default: false },
        disableTooltip: { type: Boolean, required: false, default: false },
        jobTitle: { type: String, required: false, default: '' },
        system: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            id: null,
        };
    },
    computed: {
        ...mapGetters({
            me: 'users/me/getMe',
        }),
        firstName() {
            if (this.user.first_name) {
                return this.user.first_name;
            }
            if (this.user.userFirstName) {
                return this.user.userFirstName;
            }
            return this.user.firstName;
        },
        lastName() {

            if (this.user.last_name) {
                return this.user.last_name;
            }
            if (this.user.userLastName) {
                return this.user.userLastName;
            }
            return this.user.lastName;

        },
    },
    mounted() {
        this.id = this._uid;
    },
};
</script>

<style>
.user-group .badge-label {
    @apply dark:text-gray-900;
}
</style>
