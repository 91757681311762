export const state = () => ({
    permissions: [
        // Management
        { route: 'projects', handle: ['project.read_list'] },
        { route: 'projects-id', handle: ['project.read'] },
        { route: 'projects-id-work', handle: ['project.read'] },
        { route: 'projects-id-work-shots', handle: ['project.read'] },
        { route: 'projects-id-work-dailies', handle: ['project.read', 'daily.read_list'] },
        { route: 'projects-id-work-broadcast', handle: ['project.read', 'broadcast.read_list'] },
        { route: 'projects-id-work-summarize', handle: ['project.read', 'shot.read_list', 'sequence.read_list'] },
        { route: 'projects-id-work-resources', handle: ['project.read', 'resource.read_list'] },
        { route: 'projects-id-work-tasks', handle: ['project.read', 'task.read_list'] },
        { route: 'projects-id-work-tasks-task', handle: ['project.read', 'task.read_list'] },
        { route: 'projects-id-archive', handle: ['project.update'] },
        { route: 'projects-id-details-general_information', handle: ['project.read'] },
        { route: 'projects-id-details', handle: ['project.read'] },
        { route: 'projects-id-details-bids', handle: ['project.read', 'bid.read_list'] },
        { route: 'projects-id-details-tags', handle: ['project.read', 'tag.read_list', 'tag_category.read_list'] },
        { route: 'projects-id-details-timing_and_milestones', handle: ['project.read', 'milestone.read_list'] },
        { route: 'projects-id-details-presets', handle: ['project.read', 'preset.read_list'] },
        { route: 'projects-id-details-output_settings', handle: ['project.read'] },
        { route: 'projects-id-details-project_folders', handle: ['project.read', 'sequence.read_list'] },
        { route: 'projects-id-details-team_members', handle: ['project.read'] },
        { route: 'projects-id-details-client_contacts', handle: ['project.read', 'contact.read_list'] },
        { route: 'projects-id-details-sum_details', handle: ['project.read'] },
        { route: 'projects-id-work-shots-shot', handle: ['project.read', 'shot.read', 'sequence.read_list'] },
        {
            route: 'projects-id-work-shots-shot-comp',
            handle: ['project.read', 'shot.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-cg',
            handle: ['project.read', 'shot.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-image',
            handle: ['project.read', 'shot.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-audio',
            handle: ['project.read', 'shot.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-tasks',
            handle: ['project.read', 'shot.read', 'task.read_list'],
        },

        {
            route: 'projects-id-work-shots-shot-comp-submit',
            handle: ['project.read', 'submit.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-cg-submit',
            handle: ['project.read', 'submit.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-image-submit',
            handle: ['project.read', 'submit.read', 'sequence.read_list', 'pass.read_list'],
        },
        {
            route: 'projects-id-work-shots-shot-audio-submit',
            handle: ['project.read', 'submit.read', 'sequence.read_list', 'pass.read_list'],
        },
        // Manage
        { route: 'tasks', handle: ['task.read_list'] },
        { route: 'tasks-task', handle: ['task.read_list'] },
        { route: 'manage-contacts', handle: ['contact.read_list'] },
        { route: 'manage-contacts-id', handle: ['contact.read'] },
        { route: 'manage-clients', handle: ['client.read_list'] },
        { route: 'manage-clients-id', handle: ['client.read'] },
        { route: 'manage-agencies', handle: ['agency.read_list'] },
        { route: 'tasks-id', handle: ['task.read'] },
        { route: 'manage-brands', handle: ['brand.read_list'] },

        // System
        { route: 'system-system-log', handle: ['system_log.read'] },
        { route: 'system-presets', handle: ['preset.read_list'] },
        { route: 'system-tags', handle: ['tag.read_list', 'tag_category.read_list'] },
        { route: 'users', handle: ['user.read_list'] },
        { route: 'users-id', handle: ['user.read'] },
        { route: 'system-permissions', handle: ['permission.read_list'] },
        // Other
        { route: 'notes', handle: ['note.read_list'] },
        { route: 'notes-id', handle: ['note.read'] },
        { route: 'messages', handle: ['message.read_list'] },
        { route: 'dispo-projects', handle: ['project.read_list'] },
        { route: 'dispo-timesheet', handle: ['timesheet_entry.read_list'] },
        { route: 'dispo-timesheet', handle: ['timesheet_entry.read_list'] },
        { route: 'dispo-tasks-id', handle: ['dispo_entry.read_list', 'user.read_list'] },
        { route: 'dispo-rooms-id', handle: ['room_reservation.read_list'] },
    ],
});

export const getters = {
    getPermissions(state) {
        return state.permissions;
    },
};
