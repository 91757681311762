<template>
    <v-btn icon small class="mr-2" @click="toggleColorMode">
        <i v-if="$colorMode.preference === 'light'" class="las la-sun" title="Light mode" />
        <i v-else-if="$colorMode.preference === 'dark'" class="las la-moon" title="Dark mode" />
        <i v-else class="las la-desktop" title="System"></i>
    </v-btn>
</template>

<script>
export default {
    methods: {
        toggleColorMode() {
            switch (this.$colorMode.preference) {
                case 'light':
                case 'system':
                    this.$colorMode.preference = 'dark';
                    break;
                case 'dark':
                    this.$colorMode.preference = 'light';
                    break;
            }
        },
    },
};
</script>
