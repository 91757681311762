<template>
    <div
        class="message-get rounded-t-lgp overflow-hidden bg-white shadow-card dark:bg-dark-850 w-[400px] mb-0 z-50 mr-6"
    >
        <div class="flex justify-between items-center h-12 px-4 bg-gray-300 dark:bg-dark-800 mb-7.5">
            <user-badge :user="messageData.sender" class="font-gotham-700" />
            <v-btn icon small @click="$emit('close')"><i class="las la-times" /></v-btn>
        </div>
        <div class="px-4">
            <div class="mb-4">
                <label class="form-label text-gray-500">Subject</label>
                <p class="text-xs text-secondary dark:text-white">
                    <LinkWithHandIcon
                        v-if="messageType === 2"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.entity.name}`"
                        :to="`/projects/${messageData.entity.id}`"
                        :permission="$checkPermission('project.read')"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 3"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.entity.name}`"
                        :to="`/projects/${messageData.project.id}/${projectStatus(
                            messageData.project.status,
                            'details'
                        )}/bids`"
                        :permission="$checkPermission('bid.read')"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 4"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.entity.name}`"
                        :to="`/projects/${messageData.project.id}/${projectStatus(
                            messageData.project.status,
                            'work'
                        )}/resources`"
                        :permission="$checkPermission('resource.read')"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 5"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.entity.name}`"
                        :to="`/projects/${messageData.project.id}/work/shots/${messageData.entity.id}`"
                        :permission="$checkPermission('shot.read')"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 6"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.project.name} / ${messageData.entity.id}`"
                        :to="`/projects/${messageData.project.id}/work/dailies/${messageData.entity.id}`"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 7"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${messageData.project.name} / ${messageData.entity.id}`"
                        :to="`/projects/${messageData.project.id}/work/broadcast`"
                    />

                    <LinkWithHandIcon
                        v-else-if="messageType === 8"
                        :project="messageData.project"
                        :label="`${
                            config.messages.types.find((data) => data.id === parseInt(messageData.type)).label
                        } / ${submitGroupName} / ${messageData.entity.shot.name} / ${messageData.entity.passType} / ${submitPassName} / v${messageData.entity.version}`"
                        :to="`/projects/${messageData.project.id}/work/shots/${messageData.entity.shot.id}/${messageData.entity.passType.toLowerCase()}/${messageData.entity.id}`"
                        :permission="$checkPermission('submit.read')"
                    />
                </p>
            </div>
            <hr class="border-gray-300 dark:border-dark-800 mb-4" />
            <div class="mb-4 min-h-50 text-xs max-h-[400px] overflow-y-auto">
                <p v-html="getLinkifiedHTML(messageData.payload)" />
            </div>
            <div v-if="$checkPermission('message.create')" class="mb-4">
                <v-btn block @click="updateSendMessage">Send Message</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import linkifyHtml from 'linkifyjs/html';
import { mapGetters } from 'vuex';
export default {
    props: {
        showMessage: { type: Boolean, required: false },
        messageData: { type: Object, required: false, default: null },
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
        }),
        messageType() {
            return parseInt(this.messageData.type);
        },
        submitGroupName() {
            const parts = this.messageData.entity.shot.thumbnailPath.split('/');
            return parts[parts.length-3];
        },
        submitPassName() {
            const parts = this.messageData.entity.relPath.split('/');
            return parts[parts.length-2];
        },
    },
    methods: {
        projectStatus(status, detailsOrWork) {
            switch (status) {
                case 'preproduction':
                case 'on_hold_pre':
                    return 'preproduction';
                default:
                    return `${detailsOrWork}`;
            }
        },
        updateSendMessage() {
            const payload = {
                state: true,
                entityId: this.messageData.entity ? this.messageData.entity.id : null,
                type: parseInt(this.messageData.type),
                projectID: this.messageData.project ? this.messageData.project.id : null,
                recipient: this.messageData.sender.id,
            };
            this.$store.dispatch('messages/setShowNewMessage', payload);
        },
        getLinkifiedHTML(str) {
            return linkifyHtml(str, {
                target: '_blank',
            });
        },
    },
};
</script>
