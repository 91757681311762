<template>
    <i v-if="props.sortable" :class="icon" class="las ml-2 text-base text-gray-300" />
</template>

<script>
export default {
    name: 'VtSortControl',
    props: {
        props: { type: Object, required: true },
    },
    computed: {
        icon() {
            if (!this.props.sortStatus.sorted) { return 'la-sort' }
            return this.props.sortStatus.asc ? 'la-sort-up' : 'la-sort-down'
        }
    }
}
</script>
