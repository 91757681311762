<template>
    <div class="flex items-center pr-6 mr-2 lg:pr-10 lg:mr-8 border-r border-gray-300 dark:border-dark-800">
        <v-btn fab :href="$folderPath(activeProject.relPath)" class="mr-4">
            <i class="las la-folder-open" />
        </v-btn>
        <v-menu
            v-if="!$route.name.includes('projects-id-preproduction')"
            ref="summaryMenu"
            offset-y
            :nudge-left="30"
            open-on-hover
            open-on-focus
        >
            <template #activator="{ on }">
                <v-btn fab class="mr-2" v-on="on" @click="() => { justOpened = true; }">
                    <i class="las la-info-circle" />
                </v-btn>
            </template>

            <div
                v-click-outside="() => { if (!justOpened && $refs.summaryMenu) $refs.summaryMenu._data.isActive = false; else justOpened = false; }"
                class="v-menu-body grid grid-cols-2 gap-4 px-5 py-3 text-white"
            >
                <div v-if="activeProject.producer" class="px-2">
                    <p class="font-gotham-700 mb-1 text-xs">Producer</p>
                    <div class="flex items-center">
                        <user-badge
                            :id="activeProject.producer.id"
                            icon
                            :user="activeProject.producer"
                            color="white"
                            :arrows="false"
                        />
                    </div>
                </div>
                <div v-if="activeProject.supervisor" class="px-2">
                    <p class="font-gotham-700 mb-1 text-xs">Supervisor</p>
                    <div class="flex items-center">
                        <user-badge
                            :id="activeProject.supervisor.id"
                            icon
                            :user="activeProject.supervisor"
                            color="white"
                            :arrows="false"
                        />
                    </div>
                </div>
                <div v-if="activeProject.client" class="px-2">
                    <p class="font-gotham-700 mb-1 text-xs" text-secondary>Client</p>
                    <div class="flex items-center">
                        <span class="text-gray-500 text-xs">{{ activeProject.client.name }}</span>
                    </div>
                </div>
                <div v-if="activeProject.brand" class="px-2">
                    <p class="font-gotham-700 mb-1 text-xs">Brand</p>
                    <div class="flex items-center">
                        <span class="text-gray-500 text-xs">{{ activeProject.brand.name }}</span>
                    </div>
                </div>
            </div>
        </v-menu>
        <nav v-if="false && !$route.name.includes('projects-id-preproduction')">
            <ul class="flex ml-2">
                <v-btn
                    v-hotkey="{ 'alt+w': openWork }"
                    class="rounded-r-none"
                    :to="`/projects/${$route.params.id}/work`"
                >
                    <i class="las la-hammer mr-2" />
                    Work
                </v-btn>
                <v-btn
                    v-hotkey="{ 'alt+d': openDetails }"
                    class="rounded-l-none"
                    :to="`/projects/${$route.params.id}/details`"
                >
                    <i class="las la-cog mr-2" />
                    Details
                </v-btn>
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            justOpened: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/getActiveProject',
        }),
    },
    methods: {
        openWork() {
            this.$router.push(`/projects/${this.$route.params.id}/work`);
        },
        openDetails() {
            this.$router.push(`/projects/${this.$route.params.id}/details`);
        },
    },
};
</script>
